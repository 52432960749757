import Rest from '../../../Server/Rest';
import config from '../../../settings/config';
import formatString from '../../../utilities/format-string';

const useRequisitionDetails = () => {
  const getRequisition = async (solicitudId) => {
    return new Promise((resolve, reject) => {
      const urlSearchParams = new URLSearchParams({
        include:
          'fecha_elaboracion,Grupo,Grupo.responsable,Grupo.Socios,Grupo.Sucursal,Grupo.registeredBy,montos,montos.Socio,promotor,responsable,Sucursal,registeredBy,autorizado_por,Pagos,PagosRealizados,PagosRealizados.registrado_por',
      });

      Rest.peticion(
        `${
          config.api_url
        }/classes/Solicitudes/${solicitudId}?${urlSearchParams.toString()}`,
        {
          method: 'GET',
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          const requisition = {
            ...res,
            fecha_termino: formatString.setLegibleDate(
              res.Pagos[res.Pagos.length - 1].fecha_pago.iso
            ),
            fecha_elaboracion: formatString.setLegibleDate(
              res.fecha_elaboracion.iso
            ),
            fecha_registro: formatString.setLegibleDate(res.fecha_registro.iso),
            fecha_autorizacion:
              (res.fecha_autorizacion &&
                formatString.setLegibleDate(res.fecha_autorizacion.iso)) ||
              false,
            fecha_rechazo:
              (res.fecha_rechazo &&
                formatString.setLegibleDate(res.fecha_rechazo.iso)) ||
              false,
            fecha_finalizacion:
              (res.fecha_finalizacion &&
                formatString.setLegibleDate(res.fecha_finalizacion.iso)) ||
              false,
          };

          resolve(requisition);
        })
        .catch((error) => reject(error));
    });
  };

  return {
    getRequisition,
  };
};

export default useRequisitionDetails;
