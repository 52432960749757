import React from 'react';
import _ from 'underscore';
import PaymentsMade from './PaymentsMade';
import formatString from '../../../../utilities/format-string';
import { CREDIT_GROUP } from '../../../../constants/creditTypes';

const HistoryPayments = ({ requisition, isPrint, creditType }) => {
  const setTotal = (payments) => {
    let total = 0.0;
    _.each(payments, (payment) => {
      total += payment.monto_pagado;
    });
    return formatString.setLegiblePrice(total);
  };

  const setTotalEquivalentPayments = (payments) => {
    let total = 0;
    _.each(payments, (payment) => {
      total += payment.pagos_adelantados || 0;
    });
    return total;
  };

  return (
    <div className={`${!isPrint ? 'card' : ''}`}>
      <div className={`${!isPrint ? 'card-header' : ''}`}>
        <h5 className="m-0" style={isPrint ? { fontSize: 14 } : {}}>
          Historial de pagos
        </h5>
      </div>
      <div className={`${!isPrint ? 'card-body' : ''}`}>
        <div className="table-responsive-sm">
          <table className="table">
            {requisition?.montos?.map((amount, index) => (
              <PaymentsMade
                client={requisition.montos[index].Socio}
                key={index}
                amountId={requisition.montos[index].objectId}
                pagosRealizados={_.filter(
                  requisition.PagosRealizados,
                  (item) => {
                    return _.isEqual(item.SolicitudMonto, {
                      __type: 'Pointer',
                      className: 'SolicitudesMontos',
                      objectId: amount.objectId,
                    });
                  }
                )}
                isPrint={isPrint}
                creditType={creditType}
              />
            ))}
            {creditType === CREDIT_GROUP && (
              <tbody>
                <tr>
                  <th className="pb-5"></th>
                  <th className="pb-5"></th>
                  <th className="pb-5 text-right">Total grupal</th>
                  <th className="pb-5">
                    {(requisition?.PagosRealizados?.length > 0 &&
                      setTotal(requisition.PagosRealizados)) ||
                      '$0.00'}
                  </th>
                  <th className="pb-5">
                    {(requisition?.PagosRealizados?.length > 0 &&
                      setTotalEquivalentPayments(
                        requisition.PagosRealizados
                      )) ||
                      '0'}
                  </th>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default HistoryPayments;
