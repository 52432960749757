import React from 'react';
import TableCountInfo from '../others/TableCountInfo';
import TablePagination from '../others/TablePagination';
import Spinner from '../others/Spinner';
import _ from 'underscore';
import GroupPaymentForm from './GroupPaymentForm';
import Popover from '../others/Popover';
import user from '../../settings/user';
import './../../styles/css/group.css';
import GroupListPaymentFilter from './GroupListPaymentFilter';
import Parse from 'parse';
import SyncLoader from "react-spinners/SyncLoader";
import { Link } from 'react-router-dom';
import GroupListPayment from './GroupListPayment';

class GroupListPaymentCreditsEmployee extends GroupListPayment {
    
    title = 'Pagos de créditos de empleados';
    order = 'updatedAt,-ciclo'; // orden ascendente, el objetivo es mandar al ultimo los que se pagaron recientemente.
    include =   'Grupo' +
                ',Grupo.Socios' +
                ',Sucursal' +
                ',promotor' +
                ',montos' +
                ',montos.Socio' +
                ',Pagos' +
                ',PagosRealizados' +
                ',PagosRealizados.registrado_por';

    setWhere = (data = {}) => {
        const where_fecha_pago = {
            where: {
                fecha_pago: {}
            },
            className: 'SolicitudesPagos'
        };
        if (data.dateStart) {
            where_fecha_pago.where.fecha_pago.$gte = data.dateStart;
        }    
        if (data.dateEnd) {
            where_fecha_pago.where.fecha_pago.$lte = data.dateEnd;
        }

        const config = user.config();
        const filter_from = config && config.filter_from ? {
            $gte: {
                __type: 'Date',
                iso: config.filter_from
                
            }
        } : undefined;


        let promotor = undefined;
        if (user.rol.permisos.filtros && user.rol.permisos.filtros.es_promotor === true) {
            const currentUser = Parse.User.current();
            promotor = {
                $select: {
                    query: {
                        where: {
                            User: {
                                __type: 'Pointer',
                                className: '_User',
                                objectId: currentUser.id
                    
                            }
                        },
                        className: 'Empleados'
                    },
                    key: 'objectId'
                }
            } 
        }

        if (!_.isEmpty(data.promotor)) {
            promotor = !_.isEmpty(data.promotor)? data.promotor : undefined;
        }

        const where = {
            status: data.includeDowns === false ? {
                $ne: 'baja'
            } : undefined,
            Grupo: !_.isEmpty(data.id_busqueda)? {
                $inQuery: {
                    where: {
                        id_busqueda: data.id_busqueda,
                        Empleado: {
                            $exists: true
                        }
                    },
                    className: 'Grupos'
                }
            }: {
                $inQuery: {
                    where: {
                        Empleado: {
                            $exists: true
                        }
                    },
                    className: 'Grupos'
                }
            },
            Sucursal: data.Sucursal? data.Sucursal : undefined,
            createdAt: filter_from,
            promotor: promotor,
            //prestamo_personalizado: true,
            prestamo_personalizado_interes: {
                $exists: true
            },
            prestamo_personalizado_cantidad_pagos: {
                $exists: true
            }
        }

        if (this.state.fintrar_por_fecha_finalizacion) {
            where.fecha_finalizacion = !_.isEmpty(where_fecha_pago.where.fecha_pago)? where_fecha_pago.where.fecha_pago : undefined;
            where.status = 'baja';
            // where.PagosRealizados = !_.isEmpty(where_fecha_pago.where.fecha_pago)? {
            //     $inQuery: {
            //         where: {
            //             pagado_al: where_fecha_pago.where.fecha_pago
            //         },
            //         className: 'SolicitudesPagosRealizados'
            //     }
            // }: {
            //     $exists: true
            // }
        } 
        // else {

            where.Pagos = !_.isEmpty(where_fecha_pago.where.fecha_pago)? {
                $inQuery: where_fecha_pago
            }: {
                $exists: true
            }
        // }
        
        return where;
    }

    render() {
        const {state} = this;
        return (
            <>
                <Spinner loading={state.loading} />
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-money-bill-wave mr-2"></i>
                    {this.title}
                </h3>
                <div className="card">
                    <div className="card-body">
                        
                        <GroupListPaymentFilter
                        handleChange={this.handleChange}
                        handleChangeDate={this.handleChangeDate}
                        handleFilter={this.handleFilter}
                        state={state}
                        inputGroupEnabled={!user.rol.permisos.PagosCreditosEmpleados.filtro_nombre}
                        inputDateEnabled={!user.rol.permisos.PagosCreditosEmpleados.filtro_fecha}
                        inputSucursalEnabled={!user.rol.permisos.PagosCreditosEmpleados.filtro_fecha}
                        inputPrometerEnabled={!user.rol.permisos.PagosCreditosEmpleados.filtro_promotor}
                        inputincludeDownsEnabled={false}
                        />
                        
                        <div className="text-right">
                            <div className="btn-group" role="group" aria-label="Descargas">
                                {user.rol.permisos.PagosCreditosEmpleados.descargar_excel === true && <button className="btn btn-secondary" onClick={() => this.getXLS()}>Descargar Excel</button>}
                                {user.rol.permisos.PagosCreditosEmpleados.descargar_pdf === true && <button className="btn btn-secondary" onClick={() => this.handlePrintDocument()}>Descargar PDF</button>}
                            </div>
                        </div>

                        <TableCountInfo total={state.total} />

                        <div id="table-pdf" className={`${window.innerWidth > 768 && 'table-responsive'}`} ref={this.ref}>
                            {(window.innerWidth > 768 && <>
                                {(state.collection.length > 0 && <table className="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Grupo</th>
                                            <th>Ciclo</th>
                                            {!(user.rol.permisos.filtros && user.rol.permisos.filtros.es_promotor === true) && <th>Promotor</th>}
                                            <th className="text-right">Monto desem.</th>
                                            <th className="text-right"><span className="no-show-pdf"><Popover title="Monto de pago" content="Es el pago semanal o catorcenal según sea el caso." /></span>Monto pago</th>
                                            <th>Pago actual</th>
                                            {(user.rol.nombre === 'Administrador' || user.rol.nombre === 'Contador') && <th className="text-right">Saldo</th>}
                                            <th>Fecha de término</th>
                                            <th className="no-show-pdf"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.collection.map((item, index) => <tr key={index} id={`solicitud-${item.objectId}`} id-grupo={item.groupId} className={item.pago_realizado_status?'table-success':''}>
                                            <td>{item.index}</td>
                                            <td className="td-responsive-text">{item.nombre_grupo}</td>
                                            <td className="td-responsive-text">{item.ciclo}</td>
                                            {!(user.rol.permisos.filtros && user.rol.permisos.filtros.es_promotor === true) && <td className="td-responsive-text">{item.nombre_promotor}</td>}
                                            <td className="text-right" style={{whiteSpace: 'nowrap'}}>{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format( item.monto_desembolsado )}</td>
                                            <td className="text-right" style={{whiteSpace: 'nowrap'}}>
                                                {item.pago_realizado_status && <sup><Popover title="Pago efectuado" size="normal" className="text-success" content="De acuerdo a la fecha del filtro, el pago ya fue realizado." /></sup>}
                                                {new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format( item.monto_pago )}
                                            </td>
                                            <td>{!_.isUndefined(item.pago_actual) && !_.isUndefined(item.pagos_totales) && `${item.pago_actual}/${item.pagos_totales}`}</td>
                                            {(user.rol.nombre === 'Administrador' || user.rol.nombre === 'Contador') && <td className="text-right">{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(item.saldo)}</td>}
                                            <td>{item.fecha_termino}</td>
                                            <td className="p-2 text-right no-show-pdf" style={{whiteSpace: 'nowrap'}}>
                                                <div className="btn-group" role="group">
                                                    {
                                                        !_.isEmpty(user.rol.permisos.PagosCreditosEmpleados) && user.rol.permisos.PagosCreditosEmpleados.consultar &&
                                                        <Link to={`/pagos-creditos-empleados/detalles-del-credito/${item.objectId}`} className="btn btn-link btn-sm" target="_blank"><i className="fas fa-chevron-right"></i></Link>
                                                    }
                                                </div>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={`${(user.rol.permisos.filtros && user.rol.permisos.filtros.es_promotor === true) ? 3:4}`}/>
                                            <td className="text-right">{
                                                (this.state.loadSumary === null && <SyncLoader
                                                size={8}
                                                color={"#23A1D3"}
                                                loading={true}
                                                />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_desembolsado_total)
                                            }</td>
                                            <td className="text-right">{
                                                (this.state.loadSumary === null && <SyncLoader
                                                size={8}
                                                color={"#23A1D3"}
                                                loading={true}
                                                />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_pago_total)
                                            }</td>
                                            <td></td>
                                            {((user.rol.nombre === 'Administrador' || user.rol.nombre === 'Contador') && <><td className="text-right">{
                                                (this.state.loadSumary === null && <SyncLoader
                                                size={8}
                                                color={"#23A1D3"}
                                                loading={true}
                                                />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.balanceDue_total)
                                            }</td>
                                            <td colSpan="2"/></>) || <td colSpan="3"/>}
                                        </tr>
                                        <tr>
                                            <td className="border-top-0" colSpan={`${(user.rol.permisos.filtros && user.rol.permisos.filtros.es_promotor === true) ? 3:4}`}/>
                                            <td className="text-success text-right text-uppercase" style={{whiteSpace: 'nowrap'}}>Total pagado</td>
                                            <td className="text-success text-right">
                                                {
                                                    (this.state.loadSumary === null && <SyncLoader
                                                    size={8}
                                                    color={"#23A1D3"}
                                                    loading={true}
                                                    />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_pagado_total)
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border-top-0" colSpan={`${(user.rol.permisos.filtros && user.rol.permisos.filtros.es_promotor === true) ? 3:4}`}/>
                                            <td className={`text-right text-uppercase ${(this.state.monto_pagado_total - this.state.monto_pago_total) < 0 ? 'text-danger' : 'text-info'}`}>Saldo (+/-)</td>
                                            <td className={`text-right ${(this.state.monto_pagado_total - this.state.monto_pago_total) < 0 ? 'text-danger' : 'text-info'}`}>
                                                {
                                                    (this.state.loadSumary === null && <SyncLoader
                                                    size={9}
                                                    color={"#23A1D3"}
                                                    loading={true}
                                                    />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_pagado_total - this.state.monto_pago_total)
                                                }
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>) || <div className="text-center">
                                    <div className="alert alert-warning m-0 mt-3 mb-3" role="alert">
                                    <i className="fas fa-filter"></i> Realice un filtro
                                    </div>
                                </div>}
                            </>) || <>
                                <br/>
                                {(state.collection.length > 0 && <>
                                    {state.collection.map((item, index) => <div key={index} className="position-relative">
                                            <span className="badge badge-secondary list-partner-item-counter ml-4" style={{zIndex:1}}>{item.index}</span>
                                            <div key={index} className="card mb-4 mt-3 shadow" style={{overflow: 'hidden'}}> 
                                                <div className={`card-body ${item.pago_realizado_status === false && 'bg-light'}`} style={(item.pago_realizado_status && {backgroundColor: '#83cd93'}) || {}}>
                                                    <div className="p-1 container">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span className="" style={{color: 'rgba(0,0,0,0.6)'}}>Grupo:</span>
                                                                <h5 className="font-weight-bold text-primary">
                                                                    {item.nombre_grupo}
                                                                </h5>
                                                            </div>
                                                            <div className="col-5 text-right">
                                                                <span className="" style={{color: 'rgba(0,0,0,0.6)'}}>Ciclo:</span> <b>{item.ciclo}</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {!(user.rol.permisos.filtros && user.rol.permisos.filtros.es_promotor === true) && <table className="table table-borderless m-0">
                                                        <tbody>
                                                            <tr>
                                                                <td  colSpan="2" className="p-1 " style={{color: 'rgba(0,0,0,0.6)'}}>Promotor:</td>
                                                                <th  colSpan="2" className="p-1 text-right">
                                                                    {item.nombre_promotor}
                                                                </th>
                                                            </tr>
                                                        </tbody>
                                                    </table>}
                                                    <table className="table table-borderless">     
                                                        <tbody>
                                                            <tr>

                                                            <td className="p-1 " style={{color: 'rgba(0,0,0,0.6)'}}>Monto desembolsado:</td>
                                                            <th className="p-1 text-right">
                                                                {new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format( item.monto_desembolsado )}
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <td className="p-1 " style={{color: 'rgba(0,0,0,0.6)'}}>Monto pago:</td>
                                                            <th className="p-1 text-right">
                                                                {item.pago_realizado_status && <sup><Popover title="Pago efectuado" size="normal" className="text-success" content="De acuerdo a la fecha del filtro, el pago ya fue realizado." /></sup>}
                                                                {new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format( item.monto_pago )}
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <td className="p-1 " style={{color: 'rgba(0,0,0,0.6)'}}>Pago actual:</td>
                                                            <th className="p-1 text-right">
                                                                {!_.isUndefined(item.pago_actual) && !_.isUndefined(item.pagos_totales) && `${item.pago_actual}/${item.pagos_totales}`}
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <td className="p-1 " style={{color: 'rgba(0,0,0,0.6)'}}>Fecha de término:</td>
                                                            <th className="p-1 text-right">
                                                                {item.fecha_termino}
                                                            </th>
                                                            </tr>
                                                            {(user.rol.nombre === 'Administrador' || user.rol.nombre === 'Contador') && <tr>
                                                            <td className="p-1 " style={{color: 'rgba(0,0,0,0.6)'}}>Saldo:</td>
                                                            <th className="p-1 text-right text-primary">
                                                                {new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(item.saldo)}
                                                            </th>
                                                            </tr>}
                                                        </tbody>
                                                    </table>
                                                    <div className="btn-group btn-block" role="group">
                                                        {
                                                            !_.isEmpty(user.rol.permisos.PagosCreditosEmpleados) && user.rol.permisos.PagosCreditosEmpleados.consultar &&
                                                            <Link to={`/pagos-creditos-empleados/detalles-del-credito/${item.objectId}`} className="btn btn-primary">Ver detalles <i className="fas fa-chevron-right"></i></Link>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <h5><b>Totales</b></h5>
                                            <table className="table table-borderless m-0">
                                                <tbody>
                                                    <tr>
                                                        <td className=" pl-0 ">Monto desembolsado</td>
                                                        <td className="text-right pr-0">{
                                                            (this.state.loadSumary === null && <SyncLoader
                                                            size={8}
                                                            color={"#23A1D3"}
                                                            loading={true}
                                                            />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_desembolsado_total)
                                                        }</td>
                                                    </tr>
                                                    <tr>
                                                        <td className=" pl-0 ">Monto pago</td>
                                                        <td className="text-right pr-0">{
                                                            (this.state.loadSumary === null && <SyncLoader
                                                            size={8}
                                                            color={"#23A1D3"}
                                                            loading={true}
                                                            />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_pago_total)
                                                        }</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-success pl-0 " style={{whiteSpace: 'nowrap'}}>Total pagado</td>
                                                        <td className="text-success text-right pr-0">
                                                            {
                                                                (this.state.loadSumary === null && <SyncLoader
                                                                size={8}
                                                                color={"#23A1D3"}
                                                                loading={true}
                                                                />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_pagado_total)
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={` pl-0 ${(this.state.monto_pagado_total - this.state.monto_pago_total) < 0 ? 'text-danger' : 'text-info'}`}>Saldo (+/-)</td>
                                                        <td className={`text-right pr-0 ${(this.state.monto_pagado_total - this.state.monto_pago_total) < 0 ? 'text-danger' : 'text-info'}`}>
                                                            {
                                                                (this.state.loadSumary === null && <SyncLoader
                                                                size={9}
                                                                color={"#23A1D3"}
                                                                loading={true}
                                                                />) || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(this.state.monto_pagado_total - this.state.monto_pago_total)
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>) || <div className="text-center">
                                    <div className="alert alert-warning m-0 mt-3 mb-3" role="alert">
                                    <i className="fas fa-filter"></i> Realice un filtro
                                    </div>
                                </div>}
                            </>}
                            
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <TableCountInfo total={this.state.total} />
                            </div>
                            <div className="col-md-6">
                                <TablePagination index={this.state.index} align="end" limit={this.limit} rows={this.rows} getData={this.getResource} where={this.state.where} />
                            </div>
                        </div>
                    </div>

                    <GroupPaymentForm id="modal-payment-form" 
                    requisition={state.requisitionSelected} 
                    handleFilter={this.handleFilter} 
                    pago_total_periodo={this.setPay2(state.requisitionSelected)}
                    setPaymentInfo={this.setPaymentInfo}
                    include={this.include} />

                    {!_.isEmpty(state.requisitionPrint) && <iframe id="estado-cuenta" name="estado-cuenta" width="0" height="0" src={`${this.props.match.url}/estado-cuenta?no_margin=true&is_print=true`}></iframe>}

                </div>
            </>
        );
    }
}

export default GroupListPaymentCreditsEmployee;