import React, { useEffect, useState } from 'react';
import Spinner from '../../others/Spinner';
import Resume from './components/Resume';
import { useParams } from 'react-router-dom';
import HistoryPayments from './components/HistoryPayments';
import RequisitionModel from '../../../models/RequisitionModel';
import useRequisitionDetails from './useRequisitionDetails';
import { usePaymentClient } from './context/PaymentClientProvider';
import ButtonActions from './components/ButtonActions';

const GroupRequisitionDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasPrint, setHasPrint] = useState(false);
  const [creditType, setCreditType] = useState(null);
  const { getRequisition } = useRequisitionDetails();
  const { hasRefresh, setHasRefresh, setRequisitionToPayment, requisition } =
    usePaymentClient();

  const { id_solicitud } = useParams();

  const handleRequisition = () => {
    setIsLoading(true);

    getRequisition(id_solicitud)
      .then((result) => {
        setRequisitionToPayment(result);
      })
      .catch((error) => {
        throw new Error(
          `No se pudo consultar los detalles de la solicitud ${error}`
        );
      })
      .finally(() => {
        setIsLoading(false);
        setHasRefresh(false);
      });
  };

  useEffect(() => {
    handleRequisition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasRefresh) {
      handleRequisition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRefresh]);

  useEffect(() => {
    if (requisition) {
      setCreditType(RequisitionModel.getCreditTypeByRequisition(requisition));
    }
  }, [requisition]);

  return (
    <>
      <Spinner loading={isLoading} />

      <div className="row align-items-center">
        <div className="col-8 col-sm-6 col-md-5">
          <h4 className="text-primary mt-4 mb-4">
            <i className="fas fa-file-invoice-dollar"></i> PAGOS DEL CRÉDITO
          </h4>
        </div>

        <div className="col-4 col-sm-6 col-md-7">
          <ButtonActions requisition={requisition} setHasPrint={setHasPrint} />
        </div>
      </div>

      <div className="m-3" />

      <div
        className={`${!hasPrint ? 'card' : ''}`}
        id="content-to-pdf"
        style={hasPrint ? { fontSize: 12 } : {}}
      >
        <div className={`${!hasPrint ? 'card-body' : ''}`}>
          {hasPrint && (
            <h2 className="text-primary text-center m-3">Estado de cuenta</h2>
          )}

          <Resume
            requisition={requisition}
            isPrint={hasPrint}
            creditType={creditType}
          />

          <div className="p-3" />

          <HistoryPayments
            requisition={requisition}
            isPrint={hasPrint}
            creditType={creditType}
          />
        </div>
      </div>

      <div className="m-3" />
    </>
  );
};

export default GroupRequisitionDetails;
