import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import Popover from '../../../others/Popover';
import formatString from '../../../../utilities/format-string';
import { CREDIT_GROUP } from '../../../../constants/creditTypes';

const PaymentsMade = ({ pagosRealizados, client, isPrint, creditType }) => {
  const [paymentsMade, setPaymentsMade] = useState([]);

  useEffect(() => {
    setPaymentsMade(pagosRealizados);
  }, [pagosRealizados]);

  const setTotal = (payments) => {
    let total = 0.0;
    _.each(payments, (payment) => {
      total += payment.monto_pagado;
    });
    return formatString.setLegiblePrice(total);
  };

  const setTotalEquivalentPayments = (payments) => {
    let total = 0;
    _.each(payments, (payment) => {
      total += payment.pagos_adelantados || 0;
    });
    return total;
  };

  return (
    <>
      <thead>
        {creditType === CREDIT_GROUP && (
          <tr>
            <th colSpan={5}>
              <h5
                className="text-uppercase text-primary m-0"
                style={isPrint ? { fontSize: 14 } : {}}
              >
                {client.nombre} {client.apellido_paterno}{' '}
                {client.apellido_materno}
              </h5>
            </th>
          </tr>
        )}
        <tr>
          <th className="border-top-0">#</th>
          <th className="border-top-0">Registrado al</th>
          <th className="border-top-0">Registrado por</th>
          <th className="border-top-0">Monto pagado</th>
          <th className="border-top-0">
            Pagos
            <br />
            adelantados&nbsp;
            {!isPrint && (
              <Popover
                title="Pagos adelantados"
                content="Muestra la cantidad de pagos adelantados en el abono realizado."
              />
            )}
          </th>
        </tr>
      </thead>

      {(paymentsMade.length > 0 && (
        <tbody>
          {paymentsMade.map((payment, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{formatString.setLegibleDate(payment.pagado_al.iso)}</td>
              <td className="text-uppercase">{`${payment.registrado_por.nombre} ${payment.registrado_por.apellidos}`}</td>
              <td>{formatString.setLegiblePrice(payment.monto_pagado)}</td>
              <td>{payment.pagos_adelantados || '0'}</td>
            </tr>
          ))}
          {
            <tr>
              <th className="pb-5"></th>
              <th className="pb-5"></th>
              <th className="pb-5 text-right">Total</th>
              <th className="pb-5">
                {paymentsMade.length > 0 && setTotal(paymentsMade)}
              </th>
              <th className="pb-5">
                {paymentsMade.length > 0 &&
                  setTotalEquivalentPayments(paymentsMade)}
              </th>
            </tr>
          }
        </tbody>
      )) || (
        <tbody>
          <tr className="mb-5">
            <td colSpan={5}>
              <div className="alert alert-warning text-center" role="alert">
                Sin pagos registrados
              </div>
            </td>
          </tr>
        </tbody>
      )}
    </>
  );
};

export default PaymentsMade;
