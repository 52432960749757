import _ from 'underscore';
import formatString from '../utilities/format-string';
import user from './../settings/user';
import { CREDIT_EMPLOYEE, CREDIT_GROUP, CREDIT_PERSONAL } from '../constants/creditTypes';

// factores de cobro semanal
const charge_factors_1 = {
    135: {
        interes: 0.10057, // 10.057/100
        iva_del_interes: 0.0160912, // (10.057*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$135 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            } else {
                return !(this.state.ciclo<=5)
            }
        }
    },
    127: {
        interes: 0.0776, // 7.76/100
        iva_del_interes: 0.012416, // (7.76*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$127 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            } else {
                return !(this.state.ciclo>=6 && this.state.ciclo<=11)
            }
        }
    },
    124: {
        interes: 0.06896, // 6.896/100
        iva_del_interes: 0.011033600000000001, // (6.896*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$124 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            } else {
                return !(this.state.ciclo>=12)
            }
        }
    },
    113: {
        interes: 0.1023, // 10.23/100
        iva_del_interes: 0.016368, // (10.23*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$113 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            } else {
                return !(this.state.ciclo>=6 && this.state.ciclo<=11)
            }
        }
    },
    110: {
        interes: 0.09194000000000001, // 9.194/100
        iva_del_interes: 0.014710400000000002, // (9.194*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$110 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            } else {
                return !(this.state.ciclo>=12)
            }
        }
    },
    101: {
        interes: 0.08923, // 8.923/100
        iva_del_interes: 0.014276800000000001, // (8.923*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$101 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            } else {
                return !(this.state.ciclo>=6 && this.state.ciclo<=11)
            }
        }
    },
    98: {
        interes: 0.08016, // 8.016/100
        iva_del_interes: 0.0128256, // (8.016*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$98 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            } else {
                return !(this.state.ciclo>=12)
            }
        }
    },
    90: {
        interes: 0.09484, // 9.484/100
        iva_del_interes: 0.015174400000000001, // (9.484*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$90 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            } else {
                return !(this.state.ciclo>=6 && this.state.ciclo<=11)
            }
        }
    },
    88: {
        interes: 0.08792, // 8.792/100
        iva_del_interes: 0.0140672, // (8.792*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$88 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            } else {
                return !(this.state.ciclo>=12)
            }
        }
    }
};

// factores de cobro semanal
const charge_factors_2 = {
    135: {
        interes: 0.100574, // 10/100
        iva_del_interes: 0.01609184, // (10*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$135 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    129: {
        interes: 0.08333299999999999, // 8.3/100
        iva_del_interes: 0.013333279999999998, // (8.3*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$129 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    126.5: {
        interes: 0.07615, // 7.5/100
        iva_del_interes: 0.012184000000000002, // (7.5*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$126.5 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    },
    116: {
        interes: 0.11264300000000001, // 11.2/100
        iva_del_interes: 0.01802288, // (11.2*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$116 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    113: {
        interes: 0.1023, // 10.2/100
        iva_del_interes: 0.016368, // (10.2*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$113 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    110: {
        interes: 0.091955, // 9.15/100
        iva_del_interes: 0.0147128, // (9.15*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$110 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    },
    105: {
        interes: 0.10129300000000001, // 10.05/100
        iva_del_interes: 0.01620688, // (10.05*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$105 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    101: {
        interes: 0.089224, // 8.9/100
        iva_del_interes: 0.01427584, // (8.9*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$101 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    96.5: {
        interes: 0.075646, // 7.5/100
        iva_del_interes: 0.01210336, // (7.5*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$96.5 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    },
    95: {
        interes: 0.11207, // 11.2/100
        iva_del_interes: 0.0179312, // (11.2*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$95 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    92.5: {
        interes: 0.103448, // 10.2/100
        iva_del_interes: 0.01655168, // (10.2*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$92.5 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    89.5: {
        interes: 0.09310299999999999, // 9.2/100
        iva_del_interes: 0.01489648, // (9.2*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$89.5 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    }
};

// factores de cobro semanal
const charge_factors_3 = {
    /*10 sem*/135: {
        interes: 0.1005747, // 10/100
        iva_del_interes: 0.016091952, // (10*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$135 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*10 sem*/130: {
        interes: 0.0862069, // 8.3/100
        iva_del_interes: 0.013793104, // (8.3*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$130 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*10 sem*/127: {
        interes: 0.0775862, // 7.5/100
        iva_del_interes: 0.012413792, // (7.5*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$127 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    },
    /*12 sem*/116: {
        interes: 0.1126437, // 11.2/100
        iva_del_interes: 0.018022992, // (11.2*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$116 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*12 sem*/113: {
        interes: 0.1022989, // 10.2/100
        iva_del_interes: 0.016367824, // (10.2*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$113 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*12 sem*/110: {
        interes: 0.0919540, // 9.15/100
        iva_del_interes: 0.014712640, // (9.15*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$110 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    },
    /*14 sem*/105: {
        interes: 0.1012931, // 10.05/100
        iva_del_interes: 0.016206896, // (10.05*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$105 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*14 sem*/101: {
        interes: 0.0892242, // 8.9/100
        iva_del_interes: 0.014275872, // (8.9*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$101 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*14 sem*/97: {
        interes: 0.0771552, // 7.5/100
        iva_del_interes: 0.012344832, // (7.5*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$97 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    },
    /*16 sem*/95: {
        interes: 0.1120690, // 11.2/100
        iva_del_interes: 0.017931040, // (11.2*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$95 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*16 sem*/93: {
        interes: 0.1051724, // 10.2/100
        iva_del_interes: 0.016827584, // (10.2*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$93 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*16 sem*/90: {
        interes: 0.0948276, // 9.2/100
        iva_del_interes: 0.015172416, // (9.2*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$90 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    },
};

// factores de cobro catorcenales
const charge_factors_4 = {
    270: {
        interes: 0.2011494, // 10/100
        iva_del_interes: 0.032183904, // (10*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 5,
        label: '$270 (5 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    260: {
        interes: 0.1724138, // 8.3/100
        iva_del_interes: 0.027586208, // (8.3*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 5,
        label: '$260 (5 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    254: {
        interes: 0.1551724, // 7.5/100
        iva_del_interes: 0.024827584, // (7.5*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 5,
        label: '$254 (5 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    },
    232: {
        interes: 0.2252874, // 11.2/100
        iva_del_interes: 0.036045984, // (11.2*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 6,
        label: '$232 (6 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    226: {
        interes: 0.2045978, // 10.2/100
        iva_del_interes: 0.032735648, // (10.2*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 6,
        label: '$226 (6 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    220: {
        interes: 0.183908, // 9.15/100
        iva_del_interes: 0.02942528, // (9.15*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 6,
        label: '$220 (6 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    },
    210: {
        interes: 0.2025862, // 10.05/100
        iva_del_interes: 0.032413792, // (10.05*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 7,
        label: '$210 (7 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    202: {
        interes: 0.1784484, // 8.9/100
        iva_del_interes: 0.028551744, // (8.9*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 7,
        label: '$202 (7 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    194: {
        interes: 0.1543104, // 7.5/100
        iva_del_interes: 0.024689664, // (7.5*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 7,
        label: '$194 (7 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    },
    190: {
        interes: 0.224138, // 11.2/100
        iva_del_interes: 0.03586208, // (11.2*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 8,
        label: '$190 (8 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    186: {
        interes: 0.2103448, // 10.2/100
        iva_del_interes: 0.033655168, // (10.2*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 8,
        label: '$186 (8 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    180: {
        interes: 0.1896552, // 9.2/100
        iva_del_interes: 0.030344832, // (9.2*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 8,
        label: '$180 (8 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    }
};

/**
 * estos factores de cobro deben mostrarse
 * cuando se especifique una garantía liquida 
 * en el nuevo ciclo
 */
const charge_factors_5 = {
    /*10 sem*/124.5: {
        interes: 0.08448, // 8.4480/100
        iva_del_interes: 0.0135168, // (8.4480*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$124.50 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*10 sem*/121.5: {
        interes: 0.07414, // 7.414/100
        iva_del_interes: 0.0118624, // (7.414*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$121.50 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*10 sem*/118.5: {
        interes: 0.0006379, // 6.379/100
        iva_del_interes: 0.0102064, // (6.379*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$118.50 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    },
    /*12 sem*/108: {
        interes: 0.08448, // 8.448/100
        iva_del_interes: 0.0135168, // (8.448*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$108 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*12 sem*/105: {
        interes: 0.07414, // 7.414/100
        iva_del_interes: 0.0118624, // (7.414*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$105 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*12 sem*/102: {
        interes: 0.06379, // 6.379/100
        iva_del_interes: 0.0102064, // (6.379*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$102 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    },
    /*14 sem*/96: {
        interes: 0.08448, // 8.448/100
        iva_del_interes: 0.0135168, // (8.448*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$96 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*14 sem*/93: {
        interes: 0.07414, // 7.414/100
        iva_del_interes: 0.0118624, // (7.414*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$93 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*14 sem*/90: {
        interes: 0.06379, // 6.379/100
        iva_del_interes: 0.0102064, // (6.379*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$90 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    },
    /*16 sem*/87: {
        interes: 0.08448, // 8.448/100
        iva_del_interes: 0.0135168, // (8.448*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$87 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*16 sem*/84: {
        interes: 0.07414, // 7.414/100
        iva_del_interes: 0.0118624, // (7.414*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$84 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*16 sem*/81: {
        interes: 0.06379, // 6.379/100
        iva_del_interes: 0.0102064, // (6.379*0.16)/100
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$81 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        }
    }
}

// factores de cobro semanal
const charge_factors_6 = {
    /*10 sem*/132: {
        interes: 0.11035,
        iva_del_interes: 0.017656,
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$132 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*10 sem*/130: {
        interes: 0.10345,
        iva_del_interes: 0.016552,
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$130 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*10 sem*/127: {
        interes: 0.0931,
        iva_del_interes: 0.014896,
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$127 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    /*10 sem*/125: {
        interes: 0.0862,
        iva_del_interes: 0.013792,
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$125 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    },

    /*12 sem*/116: {
        interes: 0.11265,
        iva_del_interes: 0.018024, // (11.2*0.16)/100
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$116 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*12 sem*/113: {
        interes: 0.1023,
        iva_del_interes: 0.016368,
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$113 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*12 sem*/110: {
        interes: 0.09195,
        iva_del_interes: 0.014712,
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$110 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    /*12 sem*/108: {
        interes: 0.08505,
        iva_del_interes: 0.013608,
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$108 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    },

    /*14 sem*/104: {
        interes: 0.1123,
        iva_del_interes: 0.017968,
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$104 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*14 sem*/101: {
        interes: 0.10197,
        iva_del_interes: 0.0163152,
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$101 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*14 sem*/98: {
        interes: 0.09161,
        iva_del_interes: 0.0146576,
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$98 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    /*14 sem*/96: {
        interes: 0.08473,
        iva_del_interes: 0.0135568,
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$96 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    },
    
    /*16 sem*/95: {
        interes: 0.11206,
        iva_del_interes: 0.0179296,
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$95 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*16 sem*/92: {
        interes: 0.10173,
        iva_del_interes: 0.0162768,
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$92 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*16 sem*/90: {
        interes: 0.09483,
        iva_del_interes: 0.0151728,
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$90 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    /*16 sem*/88: {
        interes: 0.08793,
        iva_del_interes: 0.0140688,
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$88 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    },
};

/**
 * estos factores de cobro deben mostrarse
 * cuando se especifique una garantía liquida 
 * en el nuevo ciclo
 */
const charge_factors_7 = {
    /*10 sem*/124: {
        interes: 0.08275,
        iva_del_interes: 0.01324,
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$124 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*10 sem*/122: {
        interes: 0.07585,
        iva_del_interes: 0.012136,
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$122 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*10 sem*/120: {
        interes: 0.06895,
        iva_del_interes: 0.011032,
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$120 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    /*10 sem*/118: {
        interes: 0.06207,
        iva_del_interes: 0.009931,
        meses: 3,
        cantidad_pagos_semanales: 10,
        label: '$118 (10 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    },

    /*12 sem*/108: {
        interes: 0.08505,
        iva_del_interes: 0.013608,
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$108 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*12 sem*/105: {
        interes: 0.0747,
        iva_del_interes: 0.011952,
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$105 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*12 sem*/103: {
        interes: 0.0678,
        iva_del_interes: 0.010848,
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$103 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    /*12 sem*/101: {
        interes: 0.06093,
        iva_del_interes: 0.009749,
        meses: 3,
        cantidad_pagos_semanales: 12,
        label: '$101 (12 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    },

    /*14 sem*/96: {
        interes: 0.08473,
        iva_del_interes: 0.013557,
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$96 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*14 sem*/93: {
        interes: 0.0744,
        iva_del_interes: 0.011904,
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$93 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*14 sem*/91: {
        interes: 0.0675,
        iva_del_interes: 0.0108,
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$91 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    /*14 sem*/89: {
        interes: 0.0606,
        iva_del_interes: 0.009696,
        meses: 4,
        cantidad_pagos_semanales: 14,
        label: '$89 (14 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    },
    
    /*16 sem*/87: {
        interes: 0.08447,
        iva_del_interes: 0.013515,
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$87 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    /*16 sem*/84: {
        interes: 0.07415,
        iva_del_interes: 0.011864,
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$84 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    /*16 sem*/82: {
        interes: 0.06725,
        iva_del_interes: 0.01076,
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$82 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    /*16 sem*/80: {
        interes: 0.06035,
        iva_del_interes: 0.009656,
        meses: 4,
        cantidad_pagos_semanales: 16,
        label: '$80 (16 semanas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    }
}

// factores de cobro catorcenales sin garantía liquida
const charge_factors_8 = {
    264: {
        interes: 0.2207,
        iva_del_interes: 0.035312,
        meses: 3,
        cantidad_pagos_semanales: 5,
        label: '$264 (5 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    260: {
        interes: 0.2069,
        iva_del_interes: 0.033104,
        meses: 3,
        cantidad_pagos_semanales: 5,
        label: '$260 (5 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    254: {
        interes: 0.1862,
        iva_del_interes: 0.029792,
        meses: 3,
        cantidad_pagos_semanales: 5,
        label: '$254 (5 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    250: {
        interes: 0.1724,
        iva_del_interes: 0.027584,
        meses: 3,
        cantidad_pagos_semanales: 5,
        label: '$250 (5 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    },

    232: {
        interes: 0.2253,
        iva_del_interes: 0.036048,
        meses: 3,
        cantidad_pagos_semanales: 6,
        label: '$232 (6 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    226: {
        interes: 0.2046,
        iva_del_interes: 0.032736,
        meses: 3,
        cantidad_pagos_semanales: 6,
        label: '$226 (6 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    220: {
        interes: 0.1839,
        iva_del_interes: 0.029424,
        meses: 3,
        cantidad_pagos_semanales: 6,
        label: '$220 (6 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    216: {
        interes: 0.1701,
        iva_del_interes: 0.027216,
        meses: 3,
        cantidad_pagos_semanales: 6,
        label: '$216 (6 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    },

    208: {
        interes: 0.2246,
        iva_del_interes: 0.035936,
        meses: 4,
        cantidad_pagos_semanales: 7,
        label: '$208 (7 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    202: {
        interes: 0.20394,
        iva_del_interes: 0.0326304,
        meses: 4,
        cantidad_pagos_semanales: 7,
        label: '$202 (7 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    196: {
        interes: 0.18322,
        iva_del_interes: 0.0293152,
        meses: 4,
        cantidad_pagos_semanales: 7,
        label: '$196 (7 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    192: {
        interes: 0.16946,
        iva_del_interes: 0.0271136,
        meses: 4,
        cantidad_pagos_semanales: 7,
        label: '$192 (7 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    },
    
    190: {
        interes: 0.22412,
        iva_del_interes: 0.0358592,
        meses: 4,
        cantidad_pagos_semanales: 8,
        label: '$190 (8 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    184: {
        interes: 0.20346,
        iva_del_interes: 0.0325536,
        meses: 4,
        cantidad_pagos_semanales: 8,
        label: '$184 (8 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    180: {
        interes: 0.18966,
        iva_del_interes: 0.0303456,
        meses: 4,
        cantidad_pagos_semanales: 8,
        label: '$180 (8 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    176: {
        interes: 0.17586,
        iva_del_interes: 0.0281376,
        meses: 4,
        cantidad_pagos_semanales: 8,
        label: '$176 (8 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    },
};

// factores de cobro catorcenales con garantía liquida
const charge_factors_9 = {
    248: {
        interes: 0.1655,
        iva_del_interes: 0.02648,
        meses: 3,
        cantidad_pagos_semanales: 5,
        label: '$248 (5 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    244: {
        interes: 0.1517,
        iva_del_interes: 0.024272,
        meses: 3,
        cantidad_pagos_semanales: 5,
        label: '$244 (5 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    240: {
        interes: 0.1379,
        iva_del_interes: 0.022064,
        meses: 3,
        cantidad_pagos_semanales: 5,
        label: '$240 (5 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    236: {
        interes: 0.12414,
        iva_del_interes: 0.019862,
        meses: 3,
        cantidad_pagos_semanales: 5,
        label: '$236 (5 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    },

    216: {
        interes: 0.1701,
        iva_del_interes: 0.027216,
        meses: 3,
        cantidad_pagos_semanales: 6,
        label: '$216 (6 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    210: {
        interes: 0.1494,
        iva_del_interes: 0.023904,
        meses: 3,
        cantidad_pagos_semanales: 6,
        label: '$210 (6 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    206: {
        interes: 0.1356,
        iva_del_interes: 0.021696,
        meses: 3,
        cantidad_pagos_semanales: 6,
        label: '$206 (6 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    202: {
        interes: 0.12186,
        iva_del_interes: 0.019498,
        meses: 3,
        cantidad_pagos_semanales: 6,
        label: '$202 (6 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    },

    192: {
        interes: 0.16946,
        iva_del_interes: 0.027114,
        meses: 4,
        cantidad_pagos_semanales: 7,
        label: '$192 (7 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    186: {
        interes: 0.1488,
        iva_del_interes: 0.023808,
        meses: 4,
        cantidad_pagos_semanales: 7,
        label: '$186 (7 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    182: {
        interes: 0.135,
        iva_del_interes: 0.0216,
        meses: 4,
        cantidad_pagos_semanales: 7,
        label: '$182 (7 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    178: {
        interes: 0.1212,
        iva_del_interes: 0.019392,
        meses: 4,
        cantidad_pagos_semanales: 7,
        label: '$178 (7 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    },
    
    174: {
        interes: 0.16894,
        iva_del_interes: 0.02703,
        meses: 4,
        cantidad_pagos_semanales: 8,
        label: '$174 (8 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo<=5)
        }
    },
    168: {
        interes: 0.1483,
        iva_del_interes: 0.023728,
        meses: 4,
        cantidad_pagos_semanales: 8,
        label: '$168 (8 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=6 && ciclo<=11)
        }
    },
    164: {
        interes: 0.1345,
        iva_del_interes: 0.02152,
        meses: 4,
        cantidad_pagos_semanales: 8,
        label: '$164 (8 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12 && ciclo<=19)
        }
    },
    160: {
        interes: 0.1207,
        iva_del_interes: 0.019312,
        meses: 4,
        cantidad_pagos_semanales: 8,
        label: '$160 (8 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=20)
        }
    },
    //Factores catorcenales credito master con garantia liquida, solo activo para ciclo >= 12
    136.5: {
        interes: 0.06293,
        iva_del_interes: 0.010069,
        meses: 5,
        cantidad_pagos_semanales: 10,
        label: '$136.5 (10 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        },
        esMaster:true

    },
    120: {
        interes: 0.06321,
        iva_del_interes: 0.010114,
        meses: 6,
        cantidad_pagos_semanales: 12,
        label: '$120 (12 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        },
        esMaster:true
    },
    108: {
        interes: 0.06306,
        iva_del_interes: 0.01009,
        meses: 7,
        cantidad_pagos_semanales: 14,
        label: '$108 (14 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        },
        esMaster:true
    },
    99: {
        interes: 0.06293,
        iva_del_interes: 0.010069,
        meses: 8,
        cantidad_pagos_semanales: 16,
        label: '$99 (16 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        },
        esMaster:true
    },

    92.5: {
        interes: 0.0637,
        iva_del_interes: 0.010192,
        meses: 9,
        cantidad_pagos_semanales: 18,
        label: '$92.5 (18 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        },
        esMaster:true
    },
    86: {
        interes: 0.062069,
        iva_del_interes: 0.009931,
        meses: 10,
        cantidad_pagos_semanales: 20,
        label: '$86 (20 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        },
        esMaster:true
    }  
}

// credito master factores de cobro bi-catorcenales con garantía liquida
const charge_factors_10 = {
    273: {
        interes: 0.12586,
        iva_del_interes: 0.020138,
        meses: 5,
        cantidad_pagos_semanales: 5,
        label: '$273 (5 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        },
        esMaster:true
    },
    240: {
        interes: 0.12642,
        iva_del_interes: 0.020228,
        meses: 6,
        cantidad_pagos_semanales: 6,
        label: '$240 (6 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        },
        esMaster:true
    },
    216: {
        interes: 0.12612,
        iva_del_interes: 0.02018,
        meses: 7,
        cantidad_pagos_semanales: 7,
        label: '$216 (7 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        },
        esMaster:true
    },
    198: {
        interes: 0.12586,
        iva_del_interes: 0.020138,
        meses: 8,
        cantidad_pagos_semanales: 8,
        label: '$198 (8 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        },
        esMaster:true
    },

    185: {
        interes: 0.1274,
        iva_del_interes: 0.020384,
        meses: 9,
        cantidad_pagos_semanales: 9,
        label: '$185 (9 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        },
        esMaster:true
    },
    172: {
        interes: 0.124138,
        iva_del_interes: 0.019862,
        meses: 10,
        cantidad_pagos_semanales: 10,
        label: '$172 (10 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo>=12)
        },
        esMaster:true
    }    
}

const charge_factors_individual_sept_2023 = [
    // 1° al 5° ciclo
    {
        charge_factor: 293.5,
        interes: 0.075,
        iva_del_interes: 0.012,
        meses: 2,
        cantidad_pagos_semanales: 4,
        label: '$293.50 (4 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 210.5,
        interes: 0.075575,
        iva_del_interes: 0.012092,
        meses: 3,
        cantidad_pagos_semanales: 6,
        label: '$210.50 (6 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 168.5,
        interes: 0.075,
        iva_del_interes: 0.012,
        meses: 4,
        cantidad_pagos_semanales: 8,
        label: '$168.50 (8 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 143.5,
        interes: 0.075,
        iva_del_interes: 0.012,
        meses: 5,
        cantidad_pagos_semanales: 10,
        label: '$143.50 (10 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 127,
        interes: 0.07528699999999999,
        iva_del_interes: 0.012045920000000002,
        meses: 6,
        cantidad_pagos_semanales: 12,
        label: '$127.00 (12 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 115,
        interes: 0.075123,
        iva_del_interes: 0.01201968,
        meses: 7,
        cantidad_pagos_semanales: 14,
        label: '$115.00 (14 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 106,
        interes: 0.075,
        iva_del_interes: 0.012,
        meses: 8,
        cantidad_pagos_semanales: 16,
        label: '$106.00 (16 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 99,
        interes: 0.074904,
        iva_del_interes: 0.01198464,
        meses: 9,
        cantidad_pagos_semanales: 18,
        label: '$99.00 (18 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 93.5,
        interes: 0.075,
        iva_del_interes: 0.012,
        meses: 10,
        cantidad_pagos_semanales: 20,
        label: '$93.50 (20 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 89,
        interes: 0.07507799999999999,
        iva_del_interes: 0.01201248,
        meses: 11,
        cantidad_pagos_semanales: 22,
        label: '$89.00 (22 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 85.5,
        interes: 0.075575,
        iva_del_interes: 0.012092,
        meses: 12,
        cantidad_pagos_semanales: 24,
        label: '$85.50 (24 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    
    // 6° al 11° ciclo
    {
        charge_factor: 290,
        interes: 0.068965,
        iva_del_interes: 0.0110344,
        meses: 2,
        cantidad_pagos_semanales: 4,
        label: '$290.00 (4 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 207,
        interes: 0.06953999999999999,
        iva_del_interes: 0.011126400000000002,
        meses: 3,
        cantidad_pagos_semanales: 6,
        label: '$207.00 (6 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 165,
        interes: 0.068965,
        iva_del_interes: 0.0110344,
        meses: 4,
        cantidad_pagos_semanales: 8,
        label: '$165.00 (8 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 140,
        interes: 0.068965,
        iva_del_interes: 0.0110344,
        meses: 5,
        cantidad_pagos_semanales: 10,
        label: '$140.00 (10 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 123.5,
        interes: 0.069253,
        iva_del_interes: 0.011080479999999998,
        meses: 6,
        cantidad_pagos_semanales: 12,
        label: '$123.50 (12 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 111.5,
        interes: 0.069089,
        iva_del_interes: 0.01105424,
        meses: 7,
        cantidad_pagos_semanales: 14,
        label: '$111.50 (14 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 102.5,
        interes: 0.068965,
        iva_del_interes: 0.0110344,
        meses: 8,
        cantidad_pagos_semanales: 16,
        label: '$102.50 (16 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 95.5,
        interes: 0.06887,
        iva_del_interes: 0.0110192,
        meses: 9,
        cantidad_pagos_semanales: 18,
        label: '$95.50 (18 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 90,
        interes: 0.068965,
        iva_del_interes: 0.0110344,
        meses: 10,
        cantidad_pagos_semanales: 20,
        label: '$90.00 (20 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 85.5,
        interes: 0.069044,
        iva_del_interes: 0.01104704,
        meses: 11,
        cantidad_pagos_semanales: 22,
        label: '$85.50 (22 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 82,
        interes: 0.06953999999999999,
        iva_del_interes: 0.011126400000000002,
        meses: 12,
        cantidad_pagos_semanales: 24,
        label: '$82.00 (24 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    
    // 12° al 19° ciclo
    {
        charge_factor: 286.5,
        interes: 0.06293,
        iva_del_interes: 0.0100688,
        meses: 2,
        cantidad_pagos_semanales: 4,
        label: '$286.50 (4 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 203.5,
        interes: 0.063507,
        iva_del_interes: 0.01016112,
        meses: 3,
        cantidad_pagos_semanales: 6,
        label: '$203.50 (6 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 161.5,
        interes: 0.06293,
        iva_del_interes: 0.0100688,
        meses: 4,
        cantidad_pagos_semanales: 8,
        label: '$161.50 (8 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 136.5,
        interes: 0.062931,
        iva_del_interes: 0.01006896,
        meses: 5,
        cantidad_pagos_semanales: 10,
        label: '$136.50 (10 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 120,
        interes: 0.063218,
        iva_del_interes: 0.01011488,
        meses: 6,
        cantidad_pagos_semanales: 12,
        label: '$120.00 (12 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 108,
        interes: 0.063054,
        iva_del_interes: 0.01008864,
        meses: 7,
        cantidad_pagos_semanales: 14,
        label: '$108.00 (14 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 99,
        interes: 0.062931,
        iva_del_interes: 0.01006896,
        meses: 8,
        cantidad_pagos_semanales: 16,
        label: '$99.00 (16 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 92.5,
        interes: 0.063697,
        iva_del_interes: 0.01019152,
        meses: 9,
        cantidad_pagos_semanales: 18,
        label: '$92.50 (18 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 86,
        interes: 0.062069,
        iva_del_interes: 0.00993104,
        meses: 10,
        cantidad_pagos_semanales: 20,
        label: '$86.00 (20 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 82,
        interes: 0.06300900000000001,
        iva_del_interes: 0.010081439999999999,
        meses: 11,
        cantidad_pagos_semanales: 22,
        label: '$82.00 (22 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 78.5,
        interes: 0.063506,
        iva_del_interes: 0.010160960000000002,
        meses: 12,
        cantidad_pagos_semanales: 24,
        label: '$78.50 (24 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    
    // 20° ciclo en adelante
    {
        charge_factor: 283,
        interes: 0.056896,
        iva_del_interes: 0.00910336,
        meses: 2,
        cantidad_pagos_semanales: 4,
        label: '$283.00 (4 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 200,
        interes: 0.05747,
        iva_del_interes: 0.0091952,
        meses: 3,
        cantidad_pagos_semanales: 6,
        label: '$200.00 (6 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 158,
        interes: 0.056897,
        iva_del_interes: 0.00910352,
        meses: 4,
        cantidad_pagos_semanales: 8,
        label: '$158.00 (8 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 133,
        interes: 0.056896,
        iva_del_interes: 0.00910336,
        meses: 5,
        cantidad_pagos_semanales: 10,
        label: '$133.00 (10 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 116.5,
        interes: 0.057184,
        iva_del_interes: 0.00914944,
        meses: 6,
        cantidad_pagos_semanales: 12,
        label: '$116.50 (12 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 104.5,
        interes: 0.05702,
        iva_del_interes: 0.0091232,
        meses: 7,
        cantidad_pagos_semanales: 14,
        label: '$104.50 (14 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 95.5,
        interes: 0.056897,
        iva_del_interes: 0.00910352,
        meses: 8,
        cantidad_pagos_semanales: 16,
        label: '$95.50 (16 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 89,
        interes: 0.057663,
        iva_del_interes: 0.00922608,
        meses: 9,
        cantidad_pagos_semanales: 18,
        label: '$89.00 (18 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 83,
        interes: 0.056897,
        iva_del_interes: 0.00910352,
        meses: 10,
        cantidad_pagos_semanales: 20,
        label: '$83.00 (20 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 78.5,
        interes: 0.056975,
        iva_del_interes: 0.009115999999999999,
        meses: 11,
        cantidad_pagos_semanales: 22,
        label: '$78.50 (22 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 75,
        interes: 0.057470999999999994,
        iva_del_interes: 0.00919536,
        meses: 12,
        cantidad_pagos_semanales: 24,
        label: '$75.00 (24 catorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
];


const charge_factors_individual_bicat_agosto_2024 = [
    // 1° al 5° ciclo
    {
        charge_factor: 587,
        interes: 0.15,
        iva_del_interes: 0.024,
        meses: 2,
        cantidad_pagos_semanales: 2,
        label: '$587.00 (2 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 421,
        interes: 0.15115,
        iva_del_interes: 0.024184,
        meses: 3,
        cantidad_pagos_semanales: 3,
        label: '$421.00 (3 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 337,
        interes: 0.15,
        iva_del_interes: 0.024,
        meses: 4,
        cantidad_pagos_semanales: 4,
        label: '$337.00 (4 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 287,
        interes: 0.15,
        iva_del_interes: 0.024,
        meses: 5,
        cantidad_pagos_semanales: 5,
        label: '$287.00 (5 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 254,
        interes: 0.150574,
        iva_del_interes: 0.02409184,
        meses: 6,
        cantidad_pagos_semanales: 6,
        label: '$254.00 (6 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 230,
        interes: 0.150246,
        iva_del_interes: 0.02403936,
        meses: 7,
        cantidad_pagos_semanales: 7,
        label: '$230.00 (7 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 212,
        interes: 0.15,
        iva_del_interes: 0.024,
        meses: 8,
        cantidad_pagos_semanales: 8,
        label: '$212.00 (8 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 198,
        interes: 0.149808,
        iva_del_interes: 0.02396928,
        meses: 9,
        cantidad_pagos_semanales: 9,
        label: '$198.00 (9 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 187,
        interes: 0.15,
        iva_del_interes: 0.024,
        meses: 10,
        cantidad_pagos_semanales: 10,
        label: '$187.00 (10 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 178,
        interes: 0.150156,
        iva_del_interes: 0.02402496,
        meses: 11,
        cantidad_pagos_semanales: 11,
        label: '$178.00 (11 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    {
        charge_factor: 171,
        interes: 0.15115,
        iva_del_interes: 0.024184,
        meses: 12,
        cantidad_pagos_semanales: 12,
        label: '$171.00 (12 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo <= 5)
        }
    },
    
    // 6° al 11° ciclo
    {
        charge_factor: 580,
        interes: 0.13793,
        iva_del_interes: 0.0220688,
        meses: 2,
        cantidad_pagos_semanales: 2,
        label: '$580.00 (2 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 414,
        interes: 0.13908,
        iva_del_interes: 0.0222528,
        meses: 3,
        cantidad_pagos_semanales: 3,
        label: '$414.00 (3 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 330,
        interes: 0.13793,
        iva_del_interes: 0.0220688,
        meses: 4,
        cantidad_pagos_semanales: 4,
        label: '$330.00 (4 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 280,
        interes: 0.13793,
        iva_del_interes: 0.0220688,
        meses: 5,
        cantidad_pagos_semanales: 5,
        label: '$280.00 (5 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 247,
        interes: 0.138506,
        iva_del_interes: 0.02216096,
        meses: 6,
        cantidad_pagos_semanales: 6,
        label: '$247.00 (6 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 223,
        interes: 0.138178,
        iva_del_interes: 0.02210848,
        meses: 7,
        cantidad_pagos_semanales: 7,
        label: '$223.00 (7 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 205,
        interes: 0.13793,
        iva_del_interes: 0.0220688,
        meses: 8,
        cantidad_pagos_semanales: 8,
        label: '$205.00 (8 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 191,
        interes: 0.13774,
        iva_del_interes: 0.0220384,
        meses: 9,
        cantidad_pagos_semanales: 9,
        label: '$191.00 (9 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 180,
        interes: 0.13793,
        iva_del_interes: 0.0220688,
        meses: 10,
        cantidad_pagos_semanales: 10,
        label: '$180.00 (10 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 171,
        interes: 0.138088,
        iva_del_interes: 0.02209408,
        meses: 11,
        cantidad_pagos_semanales: 11,
        label: '$171.00 (11 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    {
        charge_factor: 164,
        interes: 0.13908,
        iva_del_interes: 0.0222528,
        meses: 12,
        cantidad_pagos_semanales: 12,
        label: '$164.00 (12 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 6 && ciclo <= 11)
        }
    },
    
    // 12° al 19° ciclo
    {
        charge_factor: 573,
        interes: 0.12586,
        iva_del_interes: 0.0201376,
        meses: 2,
        cantidad_pagos_semanales: 2,
        label: '$573.00 (2 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 407,
        interes: 0.127014,
        iva_del_interes: 0.02032224,
        meses: 3,
        cantidad_pagos_semanales: 3,
        label: '$407.00 (3 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 323,
        interes: 0.12586,
        iva_del_interes: 0.0201376,
        meses: 4,
        cantidad_pagos_semanales: 4,
        label: '$323.00 (4 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 273,
        interes: 0.125862,
        iva_del_interes: 0.02013792,
        meses: 5,
        cantidad_pagos_semanales: 5,
        label: '$273.00 (5 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 240,
        interes: 0.126436,
        iva_del_interes: 0.02022976,
        meses: 6,
        cantidad_pagos_semanales: 6,
        label: '$240.00 (6 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 216,
        interes: 0.126108,
        iva_del_interes: 0.02017728,
        meses: 7,
        cantidad_pagos_semanales: 7,
        label: '$216.00 (7 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 198,
        interes: 0.125862,
        iva_del_interes: 0.02013792,
        meses: 8,
        cantidad_pagos_semanales: 8,
        label: '$198.00 (8 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 185,
        interes: 0.127394,
        iva_del_interes: 0.02038304,
        meses: 9,
        cantidad_pagos_semanales: 9,
        label: '$185.00 (9 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 172,
        interes: 0.124138,
        iva_del_interes: 0.01986208,
        meses: 10,
        cantidad_pagos_semanales: 10,
        label: '$172.00 (10 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 164,
        interes: 0.126018,
        iva_del_interes: 0.02016288,
        meses: 11,
        cantidad_pagos_semanales: 11,
        label: '$164.00 (11 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    {
        charge_factor: 157,
        interes: 0.127012,
        iva_del_interes: 0.02032192,
        meses: 12,
        cantidad_pagos_semanales: 12,
        label: '$157.00 (12 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 12&& ciclo <= 19)
        }
    },
    
    // 20° ciclo en adelante
    {
        charge_factor: 566,
        interes: 0.113792,
        iva_del_interes: 0.01820672,
        meses: 2,
        cantidad_pagos_semanales: 2,
        label: '$566.00 (2 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 400,
        interes: 0.11494,
        iva_del_interes: 0.0183904,
        meses: 3,
        cantidad_pagos_semanales: 3,
        label: '$400.00 (3 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 316,
        interes: 0.113794,
        iva_del_interes: 0.01820704,
        meses: 4,
        cantidad_pagos_semanales: 4,
        label: '$316.00 (4 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 266,
        interes: 0.113792,
        iva_del_interes: 0.01820672,
        meses: 5,
        cantidad_pagos_semanales: 5,
        label: '$266.00 (5 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 233,
        interes: 0.114368,
        iva_del_interes: 0.01829888,
        meses: 6,
        cantidad_pagos_semanales: 6,
        label: '$233.00 (6 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 209,
        interes: 0.11404,
        iva_del_interes: 0.0182464,
        meses: 7,
        cantidad_pagos_semanales: 7,
        label: '$209.00 (7 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 191,
        interes: 0.113794,
        iva_del_interes: 0.01820704,
        meses: 8,
        cantidad_pagos_semanales: 8,
        label: '$191.00 (8 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 178,
        interes: 0.115326,
        iva_del_interes: 0.01845216,
        meses: 9,
        cantidad_pagos_semanales: 9,
        label: '$178.00 (9 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 166,
        interes: 0.113794,
        iva_del_interes: 0.01820704,
        meses: 10,
        cantidad_pagos_semanales: 10,
        label: '$166.00 (10 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 157,
        interes: 0.11395,
        iva_del_interes: 0.018232,
        meses: 11,
        cantidad_pagos_semanales: 11,
        label: '$157.00 (11 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
    {
        charge_factor: 150,
        interes: 0.114942,
        iva_del_interes: 0.01839072,
        meses: 12,
        cantidad_pagos_semanales: 12,
        label: '$150.00 (12 bicatorcenas)',
        isDisabled: (ciclo) => {
            if (user.rol.es_administrador === true) {
                return false;
            }
            return !(ciclo >= 20)
        }
    },
];


const _setFormatDate = (myDate, format) => {
    let newDate = '';
    switch (format) {
        case 'iso':
            newDate = myDate.toISOString();
            break;
        default:
            newDate = formatString.setLegibleDate(myDate.toISOString());
            break;
    }
    return newDate;
}

/**
 * 
 * @param {*} fecha 
 * @param {*} prestamo_personalizado (ya no es necesario en esta función)
 * @param {*} prestamo_personalizado_cantidad_pagos (deprecado, pero hay registros en base de datos que las usan)
 * @param {*} prestamo_personalizado_periodicidad (deprecado, pero hay registros en base de datos que las usan)
 * @param {*} cantidad_pagos_semanales 
 * @param {*} format 
 * @param {*} sets 
 * @returns 
 */
const _setPaymentDays = (fecha, prestamo_personalizado, prestamo_personalizado_cantidad_pagos, prestamo_personalizado_periodicidad = 7, cantidad_pagos_semanales, format, sets = true) => {
    let newNumWeekArray = [];
    let numeros = [];

    if (prestamo_personalizado_cantidad_pagos) {
        for (let index = 0; index < prestamo_personalizado_cantidad_pagos; index++) {
            // hay que agregar 12 horas para ajustar la fecha cuando cambia el horario
            fecha = fecha + (1000*60*60*24*prestamo_personalizado_periodicidad);
            numeros[index] = new Date(fecha + (1000*60*60*12));
            numeros[index] = _setFormatDate(numeros[index], format);
        }
    } else {
        for (let index = 0; index < cantidad_pagos_semanales; index++) {
            // hay que agregar 12 horas para ajustar la fecha cuando cambia el horario
            fecha = fecha + (1000*60*60*24*prestamo_personalizado_periodicidad);
            numeros[index] = new Date(fecha + (1000*60*60*12));
            numeros[index] = _setFormatDate(numeros[index], format);
        }
    }

    if (sets === true) {
        // conjuntos de 5 elementos. 5 columnas de la tabla de pagos
        do{
            newNumWeekArray.push( numeros.splice(0, 5) );
        } while (numeros.length > 0);
        
        // cojuntos de 2 grupos de 5 columnas. es decir 2 tablas por conjunto
        numeros = [];
        do{
            numeros.push( newNumWeekArray.splice(0, 2) );
        } while (newNumWeekArray.length > 0);
    }

    return numeros;
}

/**
 * 
 * @param {*} fecha 
 * @param {*} prestamo_personalizado 
 * @param {*} prestamo_personalizado_cantidad_pagos (se va a deprecar)
 * @param {*} prestamo_personalizado_periodicidad (se va a deprecar)
 * @param {*} cantidad_pagos_semanales 
 * @param {*} format 
 * @param {*} sets 
 * @returns 
 */
const _getLastPaymentDay = (fecha, prestamo_personalizado, prestamo_personalizado_cantidad_pagos = undefined, prestamo_personalizado_periodicidad = undefined, cantidad_pagos_semanales, format, sets = true) => {
    let fechaFinal = _setPaymentDays(fecha.getTime(), prestamo_personalizado, prestamo_personalizado_cantidad_pagos, prestamo_personalizado_periodicidad, cantidad_pagos_semanales, format, sets);
    fechaFinal = new Date(fechaFinal[fechaFinal.length -1]);
    fechaFinal.setHours(23);
    fechaFinal.setMinutes(59);
    fechaFinal.setSeconds(59);
    fechaFinal.setMilliseconds(999);
    return fechaFinal;
}

const _setPayWeekCustomControlPagos = (requisition, monto_autorizado) => {
    let payWeek = 0.0;
    let interes = requisition.prestamo_personalizado_interes / 100;
    payWeek = Math.ceil((requisition.prestamo_personalizado_cantidad_pagos * requisition.prestamo_personalizado_periodicidad) / 30);
    payWeek = interes * payWeek;
    payWeek = payWeek + (payWeek * 0.16);
    payWeek = (monto_autorizado * payWeek) + monto_autorizado;
    payWeek = payWeek / requisition.prestamo_personalizado_cantidad_pagos;
    payWeek = parseFloat(payWeek);
    payWeek = payWeek.toFixed(2);
    payWeek = parseFloat(payWeek);
    return payWeek;
}


const _setPayWeekControlPagos = (requisition, monto_autorizado) => {
    let payWeek = 0.0;
    payWeek = (monto_autorizado / 1000) * requisition.factor_cobro;
    payWeek = parseFloat(payWeek);
    payWeek = payWeek.toFixed(2);
    payWeek = parseFloat(payWeek);
    return payWeek;
}

const _setPayControlPagos = (requisition, monto_autorizado) => {
    let pay = 0.0;
    if (requisition.prestamo_personalizado_cantidad_pagos) {
        pay = _setPayWeekCustomControlPagos(requisition, monto_autorizado);
    } else {
        pay = _setPayWeekControlPagos(requisition, monto_autorizado);
    }
    if (isNaN(pay)) {
        console.log('Error en la función _setPayControlPagos, devolvió NaN');
        return 0.0;
    }
    return pay;
}


// calcula el pago periodico exacto de un prestamo
const _setPayWeekExacto = (requisition) => {
    const items = _.where(requisition.Pagos, {numero_pago: 1});
    return _.reduce(items, (memo, item) => {
        if (item.Socio.status === 'FALLECIDA') {
            return memo;
        }
        return memo + item.monto
    }, 0);
};

// calcular el pago periodico
const _setPay2Exacto = (requisition) => {
    if (!_.isEmpty(requisition)) {
        let pay = 0.0;
        pay = _setPayWeekExacto(requisition);
        return pay;
    } else {
        return false;
    }
    
}

const _getPagoTotal = (requisition, pago_total_periodo) => {
    const {
        prestamo_personalizado, 
        cantidad_pagos_semanales, 
        prestamo_personalizado_cantidad_pagos
    } = requisition;
    let pago_total = 0.0;

    if (prestamo_personalizado_cantidad_pagos) {
        pago_total = prestamo_personalizado_cantidad_pagos * pago_total_periodo;
    } else {
        pago_total = cantidad_pagos_semanales * pago_total_periodo;
    }
    pago_total = pago_total.toFixed(2);
    pago_total = parseFloat(pago_total);
    return pago_total;
}

// controla la aparición de la ventana para cambiar
// el estatus del grupo y de los socios
const _validateCompletedPayment = (requisition, pago_total_periodo) => {
    
    if (!_.isEmpty(requisition)) {

        let pago_total = _getPagoTotal(requisition, pago_total_periodo);

        let total_pagado = 0.0;
        _.each(requisition.montos, monto => {
            if (monto.monto_acumulado) {
                total_pagado += monto.monto_acumulado;
            };
        });
        
        // si el pago total es igual a lo pagado
        // entonces mostrar la ventana
        total_pagado = total_pagado.toFixed(2);
        total_pagado = parseFloat(total_pagado);
        return total_pagado === pago_total;
    }
}

const _balanceDue = (requisition, pago_total_periodo) => {
    
    if (!_.isEmpty(requisition)) {
  
        let pago_total = _getPagoTotal(requisition, pago_total_periodo);
  
        let total_pagado = 0.0;
        _.each(requisition.PagosRealizados, Pago => {
            if (Pago.monto_pagado) {
                total_pagado += Pago.monto_pagado;
            };
        });
        
        // si el pago total es igual a lo pagado
        // entonces mostrar la ventana
        total_pagado = total_pagado.toFixed(2);
        total_pagado = parseFloat(total_pagado);
        return pago_total - total_pagado;
    }
}

const _setChargeFactorType = (prestamo_personalizado_periodicidad, charge_factor_type_default, garantia, prestamo_personalizado = false) => {
//alert(charge_factor_type_default);
    if (prestamo_personalizado) {
        if (prestamo_personalizado_periodicidad === 14 || prestamo_personalizado_periodicidad === '14') {
            return 'charge_factors_individual_sept_2023';
        }else if(prestamo_personalizado_periodicidad === 28 || prestamo_personalizado_periodicidad === '28') {
            return 'charge_factors_individual_bicat_agosto_2024';
        } else {
            return charge_factor_type_default;
        }
    }
    if ((prestamo_personalizado_periodicidad === 7 || prestamo_personalizado_periodicidad === '7') && garantia === 'LIQUIDA') {
        return 'charge_factors_7';
    } else if (prestamo_personalizado_periodicidad === 7 || prestamo_personalizado_periodicidad === '7') {
        return 'charge_factors_6';
    } else if ((prestamo_personalizado_periodicidad === 14 || prestamo_personalizado_periodicidad === '14') && garantia === 'LIQUIDA') {
        return 'charge_factors_9';
    } else if (prestamo_personalizado_periodicidad === 14 || prestamo_personalizado_periodicidad === '14') {
        return 'charge_factors_8';
    } else if ((prestamo_personalizado_periodicidad === 28 || prestamo_personalizado_periodicidad === '28') && garantia === 'LIQUIDA') {
        return 'charge_factors_10';
    // hay que ver la manera de eliminar esta validación sin afectar la lógica actual
    } else if ((prestamo_personalizado_periodicidad === 28 || prestamo_personalizado_periodicidad === '28') ) {
        return 'charge_factors_11';
    } else {
        return charge_factor_type_default;
    }
}

const formatData = (data) => {
    let newData = {};
    _.map(data, (value, key) => {
        let newValue;
        
        if (!_.isEmpty(value) && _.isString(value)) {
            switch (key) {
                case 'Sucursal':
                    newValue = {
                        __type: 'Pointer',
                        className: 'Sucursales',
                        objectId: value
                    };
                    break;
                case 'dia_pago':
                case 'cantidad_pagos_semanales':
                case 'prestamo_personalizado_cantidad_pagos':
                case 'prestamo_personalizado_periodicidad':
                    newValue = parseInt(value);
                    break;
                case 'prestamo_personalizado_interes':
                    newValue = parseFloat(value)
                    break;
                case 'montos':
                    newValue = _.map(value, (item) => {
                        item.monto_actual = parseInt(item.monto_actual);
                        item.monto_solicitado = parseInt(item.monto_solicitado);
                        item.monto_sugerido = parseInt(item.monto_sugerido);
                        return item;
                    });
                    break;
                case 'responsable_tdd_numero':
                    newValue = value.replace(/ /g, "");
                    break;
                default:
                    newValue = value.toUpperCase().trim();
                    break;
            }
        } else if (_.isObject(value)) {
            switch (key) {
                case 'aval':
                    newValue = {
                        nombre: value.nombre.toUpperCase(),
                        apellido_paterno: value.apellido_paterno.toUpperCase(),
                        apellido_materno: value.apellido_materno.toUpperCase(),
                        domicilio_direccion: value.domicilio_direccion.toUpperCase(),
                        domicilio_cp: value.domicilio_cp.toUpperCase(),
                        domicilio_entidad: value.domicilio_entidad.toUpperCase(),
                        domicilio_poblacion: value.domicilio_poblacion.toUpperCase(),
                        domicilio_colonia: value.domicilio_colonia.toUpperCase()
                    };
                    break;
                default:
                    newValue = value;
                    break;
            }
        } else {
            if (!_.isBoolean(value) && _.isEmpty(value) && !_.isNumber(value)) {
                newValue = {__op:'Delete'};
            } else {
                newValue = value;
            }
            
        }

        newData[key] = newValue;
    });

    return newData;
}

const setAmountTotal_0 = (amount_name, montos) => {
    let sum = 0;
    _.each(montos, (item) => {
        if (item[amount_name] === '') {
            sum += 0;
        } else {
            sum += parseInt(item[amount_name]);
        }
        
    });
    return sum;
}

const esMaster = (charge_factor_type, factor_cobro) => {
    if (charge_factor_type && factor_cobro) {
        const chargeFactor = this[charge_factor_type.toLowerCase()][factor_cobro];
        return chargeFactor?.esMaster || false;
    }
    return false;
}

const setRequiredWarranty = (charge_factor_type, factor_cobro, prestamo_personalizado, montos) => {

    return esMaster(charge_factor_type, factor_cobro) || (
        prestamo_personalizado && (
            setAmountTotal_0('monto_sugerido', montos) >= 9500 ||
            setAmountTotal_0('monto_aprobado', montos) >= 9500
        )
    ) || (
        setAmountTotal_0('monto_sugerido', montos) >= 25000 ||
        setAmountTotal_0('monto_aprobado', montos) >= 25000
    );
}

const setNumberPayments = (solicitud) => {
    if (solicitud.prestamo_personalizado_cantidad_pagos) {
        return solicitud.prestamo_personalizado_cantidad_pagos;
    } else {
        return solicitud.cantidad_pagos_semanales;
    }
}

const setCurrentPatmentNumber = (solicitud) => {
        
    let monto_acumulado = 0.0;
    _.each(solicitud.montos, monto => {
        if (monto.monto_acumulado) {
            monto_acumulado += monto.monto_acumulado;
        }
    });

    if (solicitud.prestamo_personalizado_cantidad_pagos) {
        monto_acumulado = monto_acumulado.toFixed(1);
        monto_acumulado = parseFloat(monto_acumulado);
    }
    
    const n_pagos = setNumberPayments(solicitud);
    const pago_recurrente = _setPay2Exacto(solicitud);
    let pago_actual = 0;
    for (let index = 0; index <= n_pagos; index++) {
        let monto_n = (pago_recurrente * index).toFixed(1);
        monto_n = parseFloat( monto_n );
        if (monto_acumulado >= monto_n) {
            pago_actual = index;
            continue;
        }
    }

    return pago_actual;
}

const getCreditTypeByRequisition = (requisition) => {
    try {
        if(
            requisition.prestamo_personalizado !== true &&
            !_.isUndefined(requisition.factor_cobro) &&
            _.isUndefined(requisition.prestamo_personalizado_interes) &&
            _.isUndefined(requisition.Empleado)
        ) {
            return CREDIT_GROUP;
        }
       
        if(requisition.prestamo_personalizado === true &&
            _.isUndefined(requisition.prestamo_personalizado_interes) &&
            _.isUndefined(requisition.Empleado)
        ) {
            return CREDIT_PERSONAL;
        }
    
        if(
            requisition.prestamo_personalizado_interes === true &&
            !_.isUndefined(requisition.prestamo_personalizado_cantidad_pagos) &&
            !_.isUndefined(requisition.Empleado)
        ) {
            return CREDIT_EMPLOYEE;
        }
    } catch (error) {
        throw new Error('No se proporcionó el parámetro "requisition"');
        
    }
}

export default {
    setPaymentDays: _setPaymentDays,
    setPayWeekCustom: _setPayWeekCustomControlPagos,
    setPayWeek: _setPayWeekControlPagos,
    setPay: _setPayControlPagos,
    getLastPaymentDay: _getLastPaymentDay,
    charge_factors_1: charge_factors_1,
    charge_factors_2: charge_factors_2,
    charge_factors_3: charge_factors_3,
    charge_factors_4: charge_factors_4,
    charge_factors_5: charge_factors_5,
    charge_factors_6: charge_factors_6,
    charge_factors_7: charge_factors_7,
    charge_factors_8: charge_factors_8,
    charge_factors_9: charge_factors_9,
    charge_factors_10:charge_factors_10,
    charge_factors_individual_sept_2023: charge_factors_individual_sept_2023,
    charge_factors_individual_bicat_agosto_2024: charge_factors_individual_bicat_agosto_2024,
    getPagoTotal: _getPagoTotal,
    validateCompletedPayment: _validateCompletedPayment,
    setPay2Exacto: _setPay2Exacto,
    balanceDue: _balanceDue,
    setChargeFactorType: _setChargeFactorType,
    formatData,
    esMaster,
    setRequiredWarranty,
    setAmountTotal_0,
    setNumberPayments,
    setCurrentPatmentNumber,
    getCreditTypeByRequisition
};