import React, { useEffect, useState } from 'react';
import user from '../../../../settings/user';
import { usePaymentClient } from '../context/PaymentClientProvider';
import html2pdf from 'html2pdf.js';
import { NavLink } from 'react-router-dom';
import RequisitionModel from '../../../../models/RequisitionModel';
import _ from 'underscore';

const ButtonActions = ({ setHasPrint }) => {
  const { showPaymentDialog, setRequisitionToPayment, requisition } =
    usePaymentClient();
  const [paymentsCompleted, setPaymentsCompleted] = useState(false);

  const generatePDF = () => {
    setHasPrint(true);

    var element = document.getElementById('content-to-pdf');

    var opt = {
      margin: 1,
      filename: `ESTADO DE CUENTA DE ${requisition.Grupo.nombre}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'cm', format: 'letter', orientation: 'portrait' },
      mode: 'avoid-all',
    };

    html2pdf().set(opt).from(element).save();

    setTimeout(() => {
      setHasPrint(false);
    }, 1);
  };

  const handleAddNewPayment = () => {
    if (!paymentsCompleted) {
      setRequisitionToPayment(requisition);
      showPaymentDialog();
    }
  };

  const handleEndCicle = () => {
    if (!(!paymentsCompleted || requisition.status === 'baja')) {
      setRequisitionToPayment(_.clone(requisition));
    }
  };

  useEffect(() => {
    const pagoTotalPeriodo = RequisitionModel.setPay2Exacto(requisition);

    const hasEndCicle = RequisitionModel.validateCompletedPayment(
      requisition,
      pagoTotalPeriodo
    );

    if (hasEndCicle) {
      setPaymentsCompleted(true);
    }
  }, [requisition]);

  return (
    <div className="row justify-content-end">
      <div className="col col-sm-6 col-md-4">
        <div className="dropdown">
          <button
            className="btn btn-primary dropdown-toggle w-100"
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            Opciones
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            {((user.rol.permisos.Pagos &&
              user.rol.permisos.Pagos.pagar === true) ||
              (user.rol.permisos.PagosCreditosEmpleados &&
                user.rol.permisos.PagosCreditosEmpleados.pagar === true) ||
              (user.rol.permisos.PagosCreditosIndividuales &&
                user.rol.permisos.PagosCreditosIndividuales.pagar ===
                  true)) && (
              <button
                class="dropdown-item"
                type="button"
                onClick={() => handleAddNewPayment()}
                disabled={paymentsCompleted}
              >
                Abonar
              </button>
            )}
            <button
              class="dropdown-item"
              type="button"
              onClick={() => handleEndCicle()}
              disabled={!paymentsCompleted || requisition.status === 'baja'}
            >
              Finalizar ciclo
            </button>
            {user.rol.permisos.Pagos &&
              user.rol.permisos.Pagos.imprimir_estadocuenta === true && (
                <button
                  class="dropdown-item"
                  type="button"
                  onClick={() => generatePDF()}
                >
                  Descargar PDF
                </button>
              )}
            {(user.rol.permisos.Pagos &&
              user.rol.permisos.Pagos.editar_pagos === true) ||
              (user.rol.permisos.PagosCreditosEmpleados &&
                user.rol.permisos.PagosCreditosEmpleados.editar_pagos ===
                  true) ||
              (user.rol.permisos.PagosCreditosIndividuales &&
                user.rol.permisos.PagosCreditosIndividuales.editar_pagos ===
                  true && (
                  <NavLink
                    to={`/pagos/edicion/${requisition?.objectId}`}
                    class="dropdown-item"
                  >
                    Editar pagos
                  </NavLink>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonActions;
