import React from 'react';
import RequisitionModel from '../../../../models/RequisitionModel';

const Resume = ({ requisition, isPrint }) => {
  if (!requisition) {
    return <></>;
  }
  return (
    <div className={`${!isPrint ? 'card' : ''}`}>
      <div className={`${!isPrint ? 'card-header' : ''}`}>
        <h5 className="m-0" style={isPrint ? { fontSize: 14 } : {}}>
          Resumen
        </h5>
      </div>
      <div className={`${!isPrint ? 'card-body' : ''}`}>
        <div className="row">
          <div className="col-6 col-md-4">
            <small>
              <label className="text-muted">Grupo</label>
            </small>
            <p>{requisition.Grupo?.nombre}</p>
          </div>
          <div className="col-6 col-md-4">
            <small>
              <label className="text-muted">Promotor</label>
            </small>
            <p>
              {`${requisition.promotor?.nombre} ${requisition.promotor?.apellido_paterno} ${requisition.promotor?.apellido_materno}`}
            </p>
          </div>
          <div className="col-6 col-md-4">
            <small>
              <label className="text-muted">Ciclo</label>
            </small>
            <p>{requisition.ciclo}</p>
          </div>
          <div className="col-6 col-md-4">
            <small>
              <label className="text-muted">No. pagos</label>
            </small>
            <p>
              {requisition.cantidad_pagos_semanales ||
                requisition.prestamo_personalizado_cantidad_pagos}
            </p>
          </div>
          <div className="col-6 col-md-4">
            <small>
              <label className="text-muted">Monto desembolsado</label>
            </small>
            <p>
              {new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
              }).format(requisition.monto_autorizado)}
            </p>
          </div>
          <div className="col-6 col-md-4">
            <small>
              <label className="text-muted">Monto pago</label>
            </small>
            <p>
              {new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
              }).format(RequisitionModel.setPay2Exacto(requisition))}
            </p>
          </div>
          <div className="col-6 col-md-4">
            <small>
              <label className="text-muted">Pago actual</label>
            </small>
            <p>
              {RequisitionModel.setCurrentPatmentNumber(requisition)}/
              {RequisitionModel.setNumberPayments(requisition)}
            </p>
          </div>
          <div className="col-6 col-md-4">
            <small>
              <label className="text-muted">Fecha de término</label>
            </small>
            <p>{requisition.fecha_termino}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
