import _ from 'underscore';
const _inPaymentPeriod = (status, fecha_elaboracion) => {
  if (status === 'autorizado') {
    if (fecha_elaboracion) {
      fecha_elaboracion.setHours(0);
      fecha_elaboracion.setMinutes(0);
      fecha_elaboracion.setMilliseconds(0);
      fecha_elaboracion = fecha_elaboracion.getTime();
      let fecha_inicio_pago = fecha_elaboracion + 1000 * 60 * 60 * 24 * 7;
      let hoy = new Date();
      hoy.setHours(0);
      hoy.setMinutes(0);
      hoy.setMilliseconds(0);
      hoy = hoy.getTime();
      return hoy >= fecha_inicio_pago;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

const _getTotalPayment = (payments, returnLastPayment = false) => {
  let debt = 0.0;
  let lastPayment = null;
  let toDay = new Date();
  toDay.setHours(23);
  toDay.setMinutes(59);
  toDay.setSeconds(59);
  toDay.setMilliseconds(999);
  toDay = toDay.getTime();

  _.each(payments, (payment) => {
    if (new Date(payment.fecha_pago.iso).getTime() <= toDay) {
      lastPayment = payment;
      debt += payment.monto;
    } else {
      debt += 0;
    }

    if (debt === 0) {
      lastPayment = payments[0];
    }
  });
  if (returnLastPayment) {
    return { debt, lastPayment };
  }
  return debt;
};

export default {
  inPaymentPeriod: _inPaymentPeriod,
  getTotalPayment: _getTotalPayment,
};
