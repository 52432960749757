import React, { Component } from 'react';
import Avatar from './../../components/user/Avatar';
import './../../styles/css/nav.css';
import user from './../../settings/user';
import { NavLink } from 'react-router-dom';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import Parse from 'parse';
import $ from 'jquery';
import { getLocalGroups } from '../groups/GroupListLocalNewCycle';

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      count_moderations: 0,
      count_authorization: 0,
      count_authorized: 0,
      count_verified: 0,
      count_documentos_por_guardar: 0,
    };
    this.btnCollapse = React.createRef();
  }

  getSociosModerations = () => {
    const params = new URLSearchParams({
      where: JSON.stringify({
        en_moderacion: true,
      }),
      limit: 0,
      count: 1,
    });
    Rest.peticion(
      `${config.api_url}/classes/SociosDiccionario?${params.toString()}`,
      {
        method: 'GET',
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        this.setState({
          count_moderations: res.count,
        });
        return res;
      })
      .catch((error) => {
        return error;
      });
  };

  getRequisitionsApprove = () => {
    let where = {};
    if (Parse.User.current().toJSON().Sucursal) {
      where = {
        Solicitudes: {
          $inQuery: {
            where: {
              status: 'autorizacion',
              Sucursal: Parse.User.current().toJSON().Sucursal,
            },
            className: 'Solicitudes',
          },
        },
      };
    } else {
      where = {
        Solicitudes: {
          $inQuery: {
            where: {
              status: 'autorizacion',
            },
            className: 'Solicitudes',
          },
        },
      };
    }
    const params = new URLSearchParams({
      where: JSON.stringify(where),
      limit: 0,
      count: 1,
    });
    Rest.peticion(`${config.api_url}/classes/Grupos?${params.toString()}`, {
      method: 'GET',
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        this.setState({
          count_authorization: res.count,
        });
        return res;
      })
      .catch((error) => {
        return error;
      });
  };

  getRequisitionsApproved = () => {
    let where = {};
    if (Parse.User.current().toJSON().Sucursal) {
      where = {
        Solicitudes: {
          $inQuery: {
            where: {
              status: 'autorizado',
              Sucursal: Parse.User.current().toJSON().Sucursal,
            },
            className: 'Solicitudes',
          },
        },
      };
    } else {
      where = {
        Solicitudes: {
          $inQuery: {
            where: {
              status: 'autorizado',
            },
            className: 'Solicitudes',
          },
        },
      };
    }
    const params = new URLSearchParams({
      where: JSON.stringify(where),
      limit: 0,
      count: 1,
    });
    Rest.peticion(`${config.api_url}/classes/Grupos?${params.toString()}`, {
      method: 'GET',
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        this.setState({
          count_authorized: res.count,
        });
        return res;
      })
      .catch((error) => {
        return error;
      });
  };

  getRequisitionsVerified = () => {
    let where = {};
    if (Parse.User.current().toJSON().Sucursal) {
      where = {
        Solicitudes: {
          $inQuery: {
            where: {
              status: 'autorizado',
              verificado: {
                $ne: true,
              },
              Sucursal: Parse.User.current().toJSON().Sucursal,
            },
            className: 'Solicitudes',
          },
        },
      };
    } else {
      where = {
        Solicitudes: {
          $inQuery: {
            where: {
              status: 'autorizado',
              verificado: {
                $ne: true,
              },
            },
            className: 'Solicitudes',
          },
        },
      };
    }
    const params = new URLSearchParams({
      where: JSON.stringify(where),
      limit: 0,
      count: 1,
    });
    Rest.peticion(`${config.api_url}/classes/Grupos?${params.toString()}`, {
      method: 'GET',
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        this.setState({
          count_verified: res.count,
        });
        return res;
      })
      .catch((error) => {
        return error;
      });
  };

  getLocalNewCycles = async () => {
    const localGroups = await getLocalGroups();
    this.setState({
      count_documentos_por_guardar: localGroups.length,
    });
  };

  timerID = false;
  timerID2 = false;
  timerID3 = false;
  timerID4 = false;
  timerID5 = false;

  componentDidMount = () => {
    if (
      user.rol.permisos.Validacion &&
      user.rol.permisos.Validacion.consultar
    ) {
      this.timerID = setInterval(() => this.getSociosModerations(), 1000 * 60);
    }
    if (
      user.rol.permisos.Autorizaciones &&
      user.rol.permisos.Autorizaciones.consultar
    ) {
      this.timerID2 = setInterval(
        () => this.getRequisitionsApprove(),
        1000 * 60
      );
    }
    if (
      user.rol.permisos.Autorizados &&
      user.rol.permisos.Autorizados.consultar
    ) {
      this.timerID3 = setInterval(
        () => this.getRequisitionsApproved(),
        1000 * 60
      );
    }
    if (
      user.rol.permisos.Verificaciones &&
      user.rol.permisos.Verificaciones.consultar
    ) {
      this.timerID4 = setInterval(
        () => this.getRequisitionsVerified(),
        1000 * 60
      );
    }
    if (user.rol.permisos.Grupos && user.rol.permisos.Grupos.registrar) {
      this.timerID5 = setInterval(() => this.getLocalNewCycles(), 1000 * 60);
    }
  };
  componentWillUnmount() {
    try {
      clearInterval(this.timerID);
      clearInterval(this.timerID2);
      clearInterval(this.timerID3);
      clearInterval(this.timerID4);
      clearInterval(this.timerID5);
    } catch (error) {
      console.log(error);
    }
  }
  UNSAFE_componentWillMount = async () => {
    if (
      user.rol.permisos.Validacion &&
      user.rol.permisos.Validacion.consultar
    ) {
      this.getSociosModerations();
    }
    if (
      user.rol.permisos.Autorizaciones &&
      user.rol.permisos.Autorizaciones.consultar
    ) {
      this.getRequisitionsApprove();
    }
    if (
      user.rol.permisos.Autorizados &&
      user.rol.permisos.Autorizados.consultar
    ) {
      this.getRequisitionsApproved();
    }
    if (
      user.rol.permisos.Verificaciones &&
      user.rol.permisos.Verificaciones.consultar
    ) {
      this.getRequisitionsVerified();
    }
    if (user.rol.permisos.Grupos && user.rol.permisos.Grupos.registrar) {
      this.getLocalNewCycles();
    }
  };

  // conmuta el menú principa
  _handle_toggleMenu = () => {
    this.setState((state) => ({
      show: !state.show,
    }));
  };

  // conmuta el menú al hacer clic en cualquier
  // elemento del menú principal
  _handle_onClick = () => {
    this.setState((state) => ({
      show: !state.show,
    }));
  };

  _toggleCollapse = (id) => {
    $('.collapse').collapse('hide');
    if (id) {
      $(id).collapse('toggle');
    }
  };

  render() {
    return (
      <>
        <aside
          id="sidebar"
          className={`d-flex flex-column bg-pink-900 ${
            this.state.show ? ' show' : ''
          }`}
        >
          <div className="d-flex align-items-center p-3">
            <Avatar />
          </div>
          <span className="text-pink p-3 pt-2 pb-2 bg-pink-999 d-flex text-uppercase">
            <small className="pl-1">
              <small>
                <i className="fas fa-bars pr-1"></i> Navegación principal
              </small>
            </small>
          </span>

          <nav id="nav" className="h-100 w-100">
            <NavLink
              onClick={() => {
                this._handle_onClick();
                this._toggleCollapse();
              }}
              to="/escritorio"
              className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
              activeClassName="active text-white"
            >
              <i className="fas fa-tachometer-alt"></i>{' '}
              <span style={styles.widthLabel}>Escritorio</span>
            </NavLink>
            <div className="has-child">
              {user.rol.permisos.Socios &&
                user.rol.permisos.Socios.consultar && (
                  <div
                    className="d-flex"
                    onClick={() => this._toggleCollapse('#collapse-socios')}
                  >
                    <NavLink
                      onClick={() => this._handle_onClick()}
                      to="/clientas"
                      className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between w-100"
                      activeClassName="active text-white"
                    >
                      <i className="fas fa-address-book"></i>
                      <span style={styles.widthLabelHasChild}>Clientas</span>
                    </NavLink>
                    <button
                      type="button"
                      className="p-3 btn text-white rounded-0"
                      onClick={() => this._toggleCollapse('#collapse-socios')}
                    >
                      <i className="fas fa-angle-down"></i>
                    </button>
                  </div>
                )}
              <div className="sub-menu collapse" id="collapse-socios">
                {user.rol.permisos.Socios &&
                  user.rol.permisos.Socios.registrar && (
                    <NavLink
                      onClick={() => this._handle_onClick()}
                      to="/clientas/registro"
                      className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                      activeClassName=""
                    >
                      <i className="fas fa-plus"></i>{' '}
                      <span style={styles.widthLabel}>Nueva clienta</span>
                    </NavLink>
                  )}
                {user.rol.permisos.Socios &&
                  user.rol.permisos.Socios.consultar && (
                    <NavLink
                      onClick={() => this._handle_onClick()}
                      to="/clientas"
                      className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                      activeClassName=""
                    >
                      <i className="fas fa-list"></i>{' '}
                      <span style={styles.widthLabel}>Todas las clientas</span>
                    </NavLink>
                  )}
              </div>
            </div>

            {user.rol.permisos.Validacion &&
              user.rol.permisos.Validacion.editar && (
                <NavLink
                  onClick={() => this._handle_onClick()}
                  to="/validacion"
                  className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                  activeClassName="active text-white"
                >
                  <i className="fas fa-address-book d-flex align-items-start">
                    <small>
                      <small>
                        <i className="fas fa-question"></i>
                      </small>
                    </small>
                  </i>
                  <span style={styles.widthLabel}>
                    Validación
                    {this.state.count_moderations > 0 && (
                      <span className="shadow" style={styles.badge}></span>
                    )}
                  </span>
                </NavLink>
              )}

            {((user.rol.permisos.Grupos &&
              user.rol.permisos.Grupos.registrar) ||
              (user.rol.permisos.CreditosIndividuales &&
                user.rol.permisos.CreditosIndividuales.registrar) ||
              user.rol.es_administrador === true ||
              (user.rol.permisos.Grupos &&
                user.rol.permisos.Grupos.wizard)) && (
              <div className="has-child">
                <div
                  className="d-flex"
                  onClick={() => this._toggleCollapse('#collapse-grupos')}
                >
                  {(((user.rol.permisos.Grupos &&
                    user.rol.permisos.Grupos.registrar) ||
                    (user.rol.permisos.CreditosIndividuales &&
                      user.rol.permisos.CreditosIndividuales.registrar) ||
                    user.rol.es_administrador === true ||
                    (user.rol.permisos.Grupos &&
                      user.rol.permisos.Grupos.wizard)) && (
                    <>
                      <div
                        className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between w-100"
                        activeClassName="active text-white"
                      >
                        <i className="fas fa-users d-flex align-items-start">
                          <small>
                            <small>
                              <i className="fas fa-dollar-sign"></i>
                            </small>
                          </small>
                        </i>
                        <span style={styles.widthLabelHasChild}>
                          Créditos
                          {this.state.count_documentos_por_guardar > 0 && (
                            <span
                              className="shadow"
                              style={styles.badge}
                            ></span>
                          )}
                        </span>
                      </div>
                      <button
                        type="button"
                        className="p-3 btn text-white rounded-0"
                        onClick={() => this._toggleCollapse('#collapse-grupos')}
                      >
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </>
                  )) || (
                    <>
                      <NavLink
                        onClick={() => this._handle_onClick()}
                        to="/grupos/nuevo-grupo"
                        className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between w-100"
                        activeClassName="active text-white"
                      >
                        <i className="fas fa-users d-flex align-items-start">
                          <small>
                            <small>
                              <i className="fas fa-dollar-sign"></i>
                            </small>
                          </small>
                        </i>
                        <span style={styles.widthLabelHasChild}>Grupos</span>
                      </NavLink>
                      <button
                        type="button"
                        className="p-3 btn text-white rounded-0"
                        onClick={() => this._toggleCollapse('#collapse-grupos')}
                      >
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </>
                  )}
                </div>
                <div className="sub-menu collapse" id="collapse-grupos">
                  {((user.rol.permisos.Grupos &&
                    user.rol.permisos.Grupos.registrar) ||
                    (user.rol.permisos.CreditosIndividuales &&
                      user.rol.permisos.CreditosIndividuales.registrar) ||
                    user.rol.es_administrador === true) && (
                    <NavLink
                      onClick={() => this._handle_onClick()}
                      to="/grupos/registro"
                      className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                      activeClassName=""
                    >
                      <i className="fas fa-plus"></i>
                      <span style={styles.widthLabel}>Nuevo crédito</span>
                    </NavLink>
                  )}
                  {user.rol.permisos.Grupos &&
                    user.rol.permisos.Grupos.consultar && (
                      <NavLink
                        onClick={() => this._handle_onClick()}
                        to="/grupos"
                        className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                        activeClassName=""
                      >
                        <i className="fas fa-list"></i>
                        <span style={styles.widthLabel}>Grupales</span>
                      </NavLink>
                    )}

                  {user.rol.permisos.CreditosIndividuales &&
                    user.rol.permisos.CreditosIndividuales.consultar && (
                      <NavLink
                        onClick={() => this._handle_onClick()}
                        to="/creditos-individuales"
                        className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between w-100"
                        activeClassName="active text-white"
                      >
                        <i className="fas fa-list"></i>
                        <span style={styles.widthLabel}>Individuales</span>
                      </NavLink>
                    )}

                  {user.rol.es_administrador === true && (
                    <NavLink
                      onClick={() => this._handle_onClick()}
                      to="/creditos-empleados"
                      className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between w-100"
                      activeClassName="active text-white"
                    >
                      <i className="fas fa-list"></i>
                      <span style={styles.widthLabel}>De empleados</span>
                    </NavLink>
                  )}
                  {/* {(user.rol.permisos.Grupos && user.rol.permisos.Grupos.registrar) && <NavLink onClick={() => this._handle_onClick()} to="/grupos/documentos-por-guardar" className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between" activeClassName="">
                                <i className="fas fa-list"></i>
                                <span style={styles.widthLabel}>
                                    Documentos por guardar
                                    {this.state.count_documentos_por_guardar > 0 && <span className="shadow" style={styles.badge}></span>}
                                </span>
                            </NavLink>} */}
                  {/* {(user.rol.permisos.Grupos && user.rol.permisos.Grupos.wizard) && <NavLink onClick={() => this._handle_onClick()} to="/grupos/nuevo-grupo" className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between" activeClassName="">
                                <i className="fas fa-plus"></i>
                                <span style={styles.widthLabel}>Nuevo grupo (experimental)</span>
                            </NavLink>}
                            {(user.rol.permisos.Grupos && user.rol.permisos.Grupos.listalocal) && <NavLink onClick={() => this._handle_onClick()} to="/grupos/grupos-por-guardar" className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between" activeClassName="">
                                <i className="fas fa-list"></i>
                                <span style={styles.widthLabel}>Borradores</span>
                            </NavLink>} */}
                </div>
              </div>
            )}

            {((user.rol.permisos.Pagos && user.rol.permisos.Pagos.consultar) ||
              (user.rol.permisos.PagosCreditosIndividuales &&
                user.rol.permisos.PagosCreditosIndividuales.consultar) ||
              (user.rol.permisos.PagosCreditosEmpleados &&
                user.rol.permisos.PagosCreditosEmpleados.consultar)) && (
              <div className="has-child">
                <div
                  className="d-flex"
                  onClick={() => this._toggleCollapse('#collapse-pagos')}
                >
                  <div
                    className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between w-100"
                    activeClassName="active text-white"
                  >
                    <i className="fas fa-money-bill-wave"></i>
                    <span style={styles.widthLabelHasChild}>Pagos</span>
                  </div>
                  <button
                    type="button"
                    className="p-3 btn text-white rounded-0"
                    onClick={() => this._toggleCollapse('#collapse-pagos')}
                  >
                    <i className="fas fa-angle-down"></i>
                  </button>
                </div>
                <div className="sub-menu collapse" id="collapse-pagos">
                  {user.rol.permisos.Pagos &&
                    user.rol.permisos.Pagos.consultar && (
                      <NavLink
                        onClick={() => this._handle_onClick()}
                        to="/pagos"
                        className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                        activeClassName="active text-white"
                      >
                        <i className="fas fa-money-bill-wave"></i>{' '}
                        <span style={styles.widthLabel}>Grupales</span>
                      </NavLink>
                    )}
                  {user.rol.permisos.PagosCreditosIndividuales &&
                    user.rol.permisos.PagosCreditosIndividuales.consultar && (
                      <NavLink
                        onClick={() => this._handle_onClick()}
                        to="/pagos-creditos-individuales"
                        className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                        activeClassName="active text-white"
                      >
                        <i className="fas fa-money-bill-wave"></i>{' '}
                        <span style={styles.widthLabel}>Individuales</span>
                      </NavLink>
                    )}
                  {user.rol.permisos.PagosCreditosEmpleados &&
                    user.rol.permisos.PagosCreditosEmpleados.consultar && (
                      <NavLink
                        onClick={() => this._handle_onClick()}
                        to="/pagos-creditos-empleados"
                        className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                        activeClassName="active text-white"
                      >
                        <i className="fas fa-money-bill-wave"></i>{' '}
                        <span style={styles.widthLabel}>De empleados</span>
                      </NavLink>
                    )}
                </div>
              </div>
            )}
            {user.rol.permisos.Autorizaciones &&
              user.rol.permisos.Autorizaciones.consultar && (
                <NavLink
                  onClick={() => this._handle_onClick()}
                  to="/autorizaciones"
                  className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                  activeClassName="active text-white"
                >
                  <i className="fas fa-users d-flex align-items-start">
                    <small>
                      <small>
                        <i className="fas fa-clock"></i>
                      </small>
                    </small>
                  </i>
                  <span style={styles.widthLabel}>
                    Autorizaciones
                    {this.state.count_authorization > 0 && (
                      <span className="shadow" style={styles.badge}></span>
                    )}
                  </span>
                </NavLink>
              )}

            {user.rol.permisos.Autorizados &&
              user.rol.permisos.Autorizados.consultar && (
                <NavLink
                  onClick={() => this._handle_onClick()}
                  to="/autorizados"
                  className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                  activeClassName="active text-white"
                >
                  <i className="fas fa-users d-flex align-items-start">
                    <small>
                      <small>
                        <i className="fas fa-check"></i>
                      </small>
                    </small>
                  </i>
                  <span style={styles.widthLabel}>
                    Autorizados
                    {this.state.count_authorized > 0 && (
                      <span className="shadow" style={styles.badge}></span>
                    )}
                  </span>
                </NavLink>
              )}
            {user.rol.permisos.Verificaciones &&
              user.rol.permisos.Verificaciones.consultar && (
                <NavLink
                  onClick={() => this._handle_onClick()}
                  to="/verificaciones"
                  className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                  activeClassName="active text-white"
                >
                  <i className="fas fa-clipboard-check d-flex"></i>
                  <span style={styles.widthLabel}>
                    Verificaciones
                    {this.state.count_verified > 0 && (
                      <span className="shadow" style={styles.badge}></span>
                    )}
                  </span>
                </NavLink>
              )}

            {user.rol.permisos.Grupos && user.rol.permisos.Grupos.consultar && (
              <div className="has-child">
                <div
                  className="d-flex"
                  onClick={() => this._toggleCollapse('#collapse-reportes')}
                >
                  {user.rol.permisos.Grupos &&
                    user.rol.permisos.Grupos.consultar && (
                      <NavLink
                        onClick={() => this._handle_onClick()}
                        to="/reportes"
                        className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between w-100"
                        activeClassName="active text-white"
                      >
                        <i className="fas fa-file-contract"></i>
                        <span style={styles.widthLabelHasChild}>Reportes</span>
                      </NavLink>
                    )}
                  <button
                    type="button"
                    className="p-3 btn text-white rounded-0"
                    onClick={() => this._toggleCollapse('#collapse-reportes')}
                  >
                    <i className="fas fa-angle-down"></i>
                  </button>
                </div>
                <div className="sub-menu collapse" id="collapse-reportes">
                  {user.rol.permisos.Grupos &&
                    user.rol.permisos.Grupos.registrar && (
                      <NavLink
                        onClick={() => this._handle_onClick()}
                        to="/reportes"
                        className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                        activeClassName=""
                      >
                        <i className="fas fa-list"></i>
                        <span style={styles.widthLabel}>
                          Reportes de pagos y desembolsos
                        </span>
                      </NavLink>
                    )}
                  {user.rol.permisos.Grupos &&
                    user.rol.permisos.Grupos.consultar && (
                      <NavLink
                        onClick={() => this._handle_onClick()}
                        to="/bitacora"
                        className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                        activeClassName=""
                      >
                        <i className="fas fa-list"></i>
                        <span style={styles.widthLabel}>Arqueo</span>
                      </NavLink>
                    )}
                  {user.rol.permisos.Grupos &&
                    user.rol.permisos.Grupos.consultar && (
                      <NavLink
                        onClick={() => this._handle_onClick()}
                        to="/bitacora/historial"
                        className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                        activeClassName=""
                      >
                        <i className="fas fa-list"></i>
                        <span style={styles.widthLabel}>
                          Bitácora de arqueos
                        </span>
                      </NavLink>
                    )}
                </div>
              </div>
            )}
            {/* {(user.rol.permisos.Reportes && user.rol.permisos.Reportes.consultar) && <NavLink onClick={() => this._handle_onClick()} to="/reportes" className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between" activeClassName="active text-white">
                        <i className="fas fa-file-contract"></i> <span style={styles.widthLabel}>Reportes</span>
                    </NavLink>} */}

            {user.rol.permisos.Contabilidad &&
              user.rol.permisos.Contabilidad.consultar && (
                <NavLink
                  onClick={() => this._handle_onClick()}
                  to="/contabilidad"
                  className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                  activeClassName="active text-white"
                >
                  <i className="fas fa-calculator"></i>{' '}
                  <span style={styles.widthLabel}>Contabilidad</span>
                </NavLink>
              )}

            {user.rol.permisos.Empleados &&
              user.rol.permisos.Empleados.consultar && (
                <div className="has-child">
                  <div
                    className="d-flex"
                    onClick={() => this._toggleCollapse('#collapse-empleados')}
                  >
                    {user.rol.permisos.Empleados &&
                      user.rol.permisos.Empleados.consultar && (
                        <NavLink
                          onClick={() => this._handle_onClick()}
                          to="/empleados"
                          className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between w-100"
                          activeClassName="active text-white"
                        >
                          <i className="fas fa-id-card"></i>
                          <span style={styles.widthLabelHasChild}>
                            Empleados
                          </span>
                        </NavLink>
                      )}
                    <button
                      type="button"
                      className="p-3 btn text-white rounded-0"
                      onClick={() =>
                        this._toggleCollapse('#collapse-empleados')
                      }
                    >
                      <i className="fas fa-angle-down"></i>
                    </button>
                  </div>
                  <div className="sub-menu collapse" id="collapse-empleados">
                    {user.rol.permisos.Empleados &&
                      user.rol.permisos.Empleados.registrar && (
                        <NavLink
                          onClick={() => this._handle_onClick()}
                          to="/empleados/registro"
                          className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                          activeClassName=""
                        >
                          <i className="fas fa-plus"></i>
                          <span style={styles.widthLabel}>Nuevo empleado</span>
                        </NavLink>
                      )}
                    {user.rol.permisos.Empleados &&
                      user.rol.permisos.Empleados.consultar && (
                        <NavLink
                          onClick={() => this._handle_onClick()}
                          to="/empleados"
                          className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                          activeClassName=""
                        >
                          <i className="fas fa-list"></i>
                          <span style={styles.widthLabel}>
                            Todos los empleados
                          </span>
                        </NavLink>
                      )}
                  </div>
                </div>
              )}

            {/* {user.rol.es_administrador === true && <NavLink onClick={() => this._handle_onClick()} to="/sucursales" className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between" activeClassName="active text-white">
                        <i className="fas fa-star"></i> <span style={styles.widthLabel}>Sucursales</span>
                    </NavLink>} */}

            {/* {user.rol.es_administrador === true && */}
            {user.rol.permisos.Contabilidad &&
              user.rol.permisos.Contabilidad.consultar && (
                <div className="has-child">
                  <div
                    className="d-flex"
                    onClick={() => this._toggleCollapse('#collapse-sucursales')}
                  >
                    <NavLink
                      onClick={() => this._handle_onClick()}
                      to="/catCuentas/list"
                      className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between w-100"
                      activeClassName="active text-white"
                    >
                      {/* <i className="fas fa-users"></i> */}
                      <i className="fas fa-calculator"></i>

                      <span style={styles.widthLabelHasChild}>
                        Contabilidad v2
                      </span>
                    </NavLink>
                    <button
                      type="button"
                      className="p-3 btn text-white rounded-0"
                      onClick={() =>
                        this._toggleCollapse('#collapse-sucursales')
                      }
                    >
                      <i className="fas fa-angle-down"></i>
                    </button>
                  </div>
                  <div className="sub-menu collapse" id="collapse-sucursales">
                    <NavLink
                      onClick={() => this._handle_onClick()}
                      to="/catCuentas/registro"
                      className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                      activeClassName=""
                    >
                      <i className="fas fa-plus"></i>
                      <span style={styles.widthLabel}>Nueva cuenta</span>
                    </NavLink>
                    <NavLink
                      onClick={() => this._handle_onClick()}
                      to="/catCuentas/list"
                      className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                      activeClassName=""
                    >
                      <i className="fas fa-list"></i>
                      <span style={styles.widthLabel}>Todas las cuentas</span>
                    </NavLink>
                    <NavLink
                      onClick={() => this._handle_onClick()}
                      to="/sucursales"
                      className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                      activeClassName=""
                    >
                      <i className="fas fa-cog"></i>
                      <span style={styles.widthLabel}>
                        Configuración de contabilidad
                      </span>
                    </NavLink>
                    <NavLink
                      onClick={() => this._handle_onClick()}
                      to="/pagos&Desembolsos"
                      className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                      activeClassName=""
                    >
                      <i className="fas fa-file-alt"></i>
                      <span style={styles.widthLabel}>Pagos y Desembolsos</span>
                    </NavLink>
                    <NavLink
                      onClick={() => this._handle_onClick()}
                      to="/listaPolizas"
                      className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                      activeClassName=""
                    >
                      <i className="fas fa-list"></i>
                      <span style={styles.widthLabel}>Listado de polizas</span>
                    </NavLink>
                  </div>
                </div>
              )}

            {user.rol.es_administrador === true && (
              <NavLink
                onClick={() => this._handle_onClick()}
                to="/configuracion"
                className="nav-link p-3 text-pink-600 d-flex align-items-center justify-content-between"
                activeClassName="active text-white"
              >
                <i className="fas fa-cog"></i>{' '}
                <span style={styles.widthLabel}>Configuración</span>
              </NavLink>
            )}

            <br />
          </nav>
        </aside>
        <button
          type="button"
          style={{ width: 50, height: 50 }}
          id="btn-nav"
          onClick={() => this._handle_toggleMenu()}
          className="btn btn-primary btn-lg rounded-circle shadow d-flex align-items-center justify-content-center d-lg-none"
        >
          {((user.rol.permisos.Validacion &&
            user.rol.permisos.Validacion.editar &&
            this.state.count_moderations) ||
            (user.rol.permisos.Autorizaciones &&
              user.rol.permisos.Autorizaciones.consultar &&
              this.state.count_authorization) ||
            (user.rol.permisos.Autorizados &&
              user.rol.permisos.Autorizados.consultar &&
              this.state.count_authorized)) && (
            <span style={styles.badgeMobile}></span>
          )}
          <i className="fas fa-bars"></i>
        </button>
      </>
    );
  }
}

export default Nav;

const styles = {
  widthLabel: {
    width: 160,
  },
  widthLabelHasChild: {
    width: 115,
  },
  badge: {
    position: 'relative',
    display: 'inline-block',
    width: 12,
    height: 12,
    top: -5,
    borderRadius: 100,
    backgroundColor: '#E91E63',
  },
  badgeMobile: {
    position: 'absolute',
    display: 'inline-block',
    width: 12,
    height: 12,
    padding: 9,
    top: -5,
    left: 0,
    borderRadius: 100,
    backgroundColor: 'rgb(233, 30, 99)',
    boxShadow: '1px 1px 3px -1px black',
  },
};
