import React, { useState, useEffect, useCallback } from 'react';
import _ from 'underscore';
import formatString from '../../../../utilities/format-string';
import requisitions_service from '../../../../services/requisitions';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import config from '../../../../settings/config';
import Parse from 'parse';
import Rest from '../../../../Server/Rest';
import Spinner from '../../../others/Spinner';
import { usePaymentClient } from '../context/PaymentClientProvider';

const defaultMaskOptions = {
  allowDecimal: true,
  allowLeadingZeroes: false,
  allowNegative: false,
  decimalLimit: 2,
  decimalSymbol: '.',
  includeThousandsSeparator: true,
  integerLimit: 7,
  prefix: '$',
  suffix: '',
  thousandsSeparatorSymbol: ',',
};

const currencyMask = createNumberMask({
  ...defaultMaskOptions,
});

const PaymentForm = () => {
  const currentUser = Parse.User.current().toJSON();

  const { setHasRefresh, requisition } = usePaymentClient();

  const initialState = {
    isVisibleModal: false,
    loading: false,
    currentClientId: '',
    currentAmount: null,
    currentPayment: null,
    regularPayment: 0,
    balanceInFavor: 0,
    totalPaymentToDate: 0,
    cumulativeAmount: 0,
    requiredPayment: 0,
    newPaymentValue: 0,
    allowNewPayment: false,
    errorMessase: '',
  };

  const [loading, setLoading] = useState(initialState.loading);
  const [currentClientId, setCurrentClientId] = useState(
    initialState.currentClientId
  );
  const [currentAmount, setCurrentAmount] = useState(
    initialState.currentAmount
  );
  const [currentPayment, setCurrentPayment] = useState(
    initialState.currentPayment
  );
  const [regularPayment, setRegularPayment] = useState(
    initialState.regularPayment
  );
  const [balanceInFavor, setBalanceInFavor] = useState(
    initialState.balanceInFavor
  );
  const [totalPaymentToDate, setTotalPaymentToDate] = useState(
    initialState.totalPaymentToDate
  );
  const [cumulativeAmount, setCumulativeAmount] = useState(
    initialState.cumulativeAmount
  );
  const [requiredPayment, setRequiredPayment] = useState(
    initialState.requiredPayment
  );
  const [newPaymentValue, setNewPaymentValue] = useState(
    initialState.newPaymentValue
  );
  const [allowNewPayment, setAllowNewPayment] = useState(
    initialState.allowNewPayment
  );
  const [errorMessase, setErrorMessage] = useState(initialState.errorMessase);

  const getTotalPayment = requisitions_service.getTotalPayment;

  const reset = () => {
    setLoading(initialState.loading);
    setCurrentClientId(initialState.currentClientId);
    setCurrentAmount(initialState.currentAmount);
    setCurrentPayment(initialState.currentPayment);
    setRegularPayment(initialState.regularPayment);
    setBalanceInFavor(initialState.balanceInFavor);
    setTotalPaymentToDate(initialState.totalPaymentToDate);
    setCumulativeAmount(initialState.cumulativeAmount);
    setRequiredPayment(initialState.requiredPayment);
    setNewPaymentValue(initialState.newPaymentValue);
    setAllowNewPayment(initialState.allowNewPayment);
  };

  const handleCurrentDebt = useCallback(() => {
    if (!requisition) {
      return;
    }

    const payments = _.filter(requisition.Pagos, (payment) => {
      return _.isEqual(payment.Socio, {
        __type: 'Pointer',
        className: 'Socios',
        objectId: currentClientId,
      });
    });

    const amount = _.filter(requisition.montos, (monto) => {
      return monto.Socio.objectId === currentClientId;
    })[0];

    setCurrentAmount(amount);

    let monto_acumulado = amount.monto_acumulado || 0.0;

    setCumulativeAmount(monto_acumulado);

    let { debt, lastPayment } = getTotalPayment(payments, true);

    setTotalPaymentToDate(debt);

    setCurrentPayment(lastPayment);

    const result = monto_acumulado - debt;

    if (result < 0) {
      // cuando tiene saldo a favor
      setBalanceInFavor(0);
    } else if (result > 0) {
      // cuando tiene deuda
      setBalanceInFavor(Math.abs(result));
    } else {
      // cuando no debe nada
      setBalanceInFavor(0);
    }
  }, [currentClientId, getTotalPayment, requisition]);

  const handleRegularPayment = useCallback(() => {
    if (!requisition) {
      return;
    }

    const pago = _.findWhere(requisition.Pagos, (pago) => {
      return (
        _.isEqual(pago.Socio, {
          __type: 'Pointer',
          className: 'Socios',
          objectId: currentClientId,
        }) && _.isEqual(pago.numero_pago, 1)
      );
    });

    setRegularPayment(pago.monto);
  }, [currentClientId, requisition]);

  const getTotal = () => {
    if (!requisition) {
      return;
    }

    return _.reduce(
      requisition.Pagos,
      (memo, payment) => {
        if (
          _.isEqual(payment.Socio, {
            __type: 'Pointer',
            className: 'Socios',
            objectId: currentClientId,
          })
        )
          return memo + payment.monto;
        else return memo;
      },
      0
    );
  };

  const getNewAmout = (amount = 0) => {
    const amountObj = _.filter(requisition.montos, (monto) => {
      return monto.Socio.objectId === currentClientId;
    })[0];

    return amountObj.monto_acumulado
      ? amountObj.monto_acumulado + amount
      : amount;
  };

  const isValidNewPayment = (amount = 0) => {
    const totalPayment = getTotal();

    const newAmount = getNewAmout(amount);

    if (newAmount > totalPayment) {
      setErrorMessage('El monto no puede ser mayor al total a pagar');
    }

    return newAmount <= totalPayment;
  };

  const handleChangeNewPayment = (formattedValue) => {
    setErrorMessage('');

    if (formattedValue) {
      const value = parseFloat(formattedValue.replace(/[$,]/g, ''));

      setNewPaymentValue(value);

      setAllowNewPayment(!isValidNewPayment(value));
    } else setNewPaymentValue(0);
  };

  const setEquivalentPayments = () => {
    try {
      if (currentClientId) {
        // si no hay deuda acumulada dividir el abono por el monto recurrente
        if (requiredPayment === 0) {
          return Math.floor(newPaymentValue / regularPayment);
        } else {
          // determinar si la cantidad abonada es mayor a la deuda para
          // calcular los pagos adelantados
          const res = newPaymentValue - requiredPayment;
          if (res > 0) {
            return Math.floor(res / regularPayment);
          } else {
            return 0;
          }
        }
      } else return 0;
    } catch (error) {
      return 0;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPaymentValue === 0 || allowNewPayment) {
      alert('Escriba una cantidad válida');
      return;
    }

    let nuevoMontoAcumulado = 0;

    if (currentAmount.monto_acumulado) {
      nuevoMontoAcumulado = currentAmount.monto_acumulado + newPaymentValue;
    } else {
      nuevoMontoAcumulado = newPaymentValue;
    }

    const pagado_al = {
      __type: 'Date',
      iso: new Date().toISOString(),
    };

    const requests = [];

    requests.push({
      method: 'POST',
      path: `${config.api_path2}/classes/SolicitudesPagosRealizados`,
      body: {
        SolicitudMonto: {
          __type: 'Pointer',
          className: 'SolicitudesMontos',
          objectId: currentAmount.objectId,
        },
        SolicitudPago: {
          __type: 'Pointer',
          className: 'SolicitudesPagos',
          objectId: currentPayment.objectId,
        },
        registrado_por: {
          __type: 'Pointer',
          className: '_User',
          objectId: currentUser.objectId,
        },
        Solicitud: {
          __type: 'Pointer',
          className: 'Solicitudes',
          objectId: requisition.objectId,
        },
        monto_pagado: newPaymentValue,
        pagado_al,
        pagos_adelantados: setEquivalentPayments(),
      },
    });

    requests.push({
      method: 'PUT',
      path: `${config.api_path2}/classes/SolicitudesMontos/${currentAmount.objectId}`,
      body: {
        ultima_fecha_pago: pagado_al,
        monto_acumulado: nuevoMontoAcumulado,
      },
    });

    setLoading(true);

    Rest.peticion(`${config.api_url}/batch`, {
      method: 'POST',
      body: JSON.stringify({
        requests,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then(() => {
        setHasRefresh(true);
        alert('El pago han sido registrado con éxito');
      })
      .catch(() => {
        alert('El pago no fue registrado. Intente más tarde.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (currentClientId) {
      handleRegularPayment();
      handleCurrentDebt();
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClientId, handleCurrentDebt, handleRegularPayment]);

  useEffect(() => {
    if (cumulativeAmount >= totalPaymentToDate) {
      const result = 0;
      setRequiredPayment(result);
      return;
    }

    if (balanceInFavor) {
      const amount = regularPayment - balanceInFavor;
      const result = amount > 0 ? amount : 0;
      setRequiredPayment(result);
      return;
    }

    if (totalPaymentToDate) {
      const result = totalPaymentToDate - cumulativeAmount;
      setRequiredPayment(result);
      return;
    }
  }, [regularPayment, balanceInFavor, totalPaymentToDate, cumulativeAmount]);

  useEffect(() => {
    if (requisition) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requisition]);

  return (
    <div className="row">
      <Spinner loading={loading} />
      <div className="col-12 col-md-6">
        <div className="form-group">
          <label>
            <small className="text-muted">Cliente</small>
          </label>

          <select
            className="form-control"
            onChange={(event) => setCurrentClientId(event.currentTarget.value)}
            value={currentClientId}
          >
            <option value="">SELECCIONE UN CLIENTE</option>
            {requisition?.Grupo?.Socios.map((socio, index) => (
              <option key={index} value={socio.objectId}>
                {`${socio.nombre} ${socio.apellido_paterno} ${socio.apellido_materno}`}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>
            <small className="text-muted">Falta por pagar</small>
          </label>
          <p>{formatString.setLegiblePrice(getTotal() - cumulativeAmount)}</p>
        </div>

        <div className="form-group">
          <label>
            <small className="text-muted">Pago regular</small>
          </label>
          <p>{formatString.setLegiblePrice(regularPayment)}</p>
        </div>

        <div className="form-group">
          <label>
            <small className="text-muted">Saldo a favor</small>
          </label>
          <p>{formatString.setLegiblePrice(balanceInFavor)}</p>
        </div>

        <div className="form-group">
          <label>
            <small className="text-muted">Pago requerido</small>
          </label>
          <p>{formatString.setLegiblePrice(requiredPayment)}</p>
        </div>

        <div className="p-3" />
      </div>
      <div className="col-12 col-md-6 d-flex align-items-stretch">
        <div className="card w-100">
          <div className="card-header">Nuevo abono</div>

          <form
            onSubmit={handleSubmit}
            className="card-body d-flex flex-column justify-content-between"
          >
            <div className="mb-3 row">
              <label htmlFor="input-monto" className="col-sm-3 col-form-label">
                Monto
              </label>

              <div className="col-sm-9">
                <MaskedInput
                  mask={currencyMask}
                  className={`form-control mb-3 ${
                    allowNewPayment ? 'is-invalid' : ''
                  }`}
                  placeholder="$0.00"
                  guide={false}
                  id="input-monto"
                  value={newPaymentValue}
                  onChange={(event) =>
                    handleChangeNewPayment(event.currentTarget.value)
                  }
                  required
                />

                {allowNewPayment && (
                  <div class="invalid-feedback">
                    {errorMessase ?? 'El monto no es correcto.'}
                  </div>
                )}

                <p>Pagos adelantados: {setEquivalentPayments()}</p>
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-success btn-block"
              disabled={!currentClientId}
            >
              Registrar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
